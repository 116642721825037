import { combineReducers } from "./combineReducers";
import { dataReducer } from "./data/data.reducer";
import { userReducer } from "./user/user.reducer";

export const initialState: AppState = {
  user: {
    isLoggedin: false,
    loading: false,
    user_name: "",
    userData: {},
  },
  data: {
    dataLoading: false,
    dataLoadingOngoingDeliverys: false,
    dataLoadingPastDeliverys: false,
    dataLoadingFeedbackOptions: false,
    dataLoadingDrivers: false,
    dataLoadingVehicles: false,
    dataLoadingLossFormOfflineData: false,
    dataLoadingChecklist: false,
    dataLoadingTank: false,
    dataLoadingJustify: false,
    dataLoadingOrder: false,
    dataLoadingFeedback: false,
    dataLoadingTransportLossAll: false,
    _isJustifyLoaded: null,
    _isTankLoaded: null,
    _isChecklistLoaded: null,
    _ongoingDeliverysLoaded: null,
    _pastDeliverysLoaded: null,
    _orderLoaded: null,
    _feedbackLoaded: null,
    _transportLossAllLoaded: null,
    ongoingDeliverysMessage: "",
    ongoingDeliverysStatus: "",
    pastDeliverysMessage: "",
    pastDeliverysStatus: "",
    orderMessage: "",
    orderStatus: "",
    feedbackMessage: "",
    feedbackStatus: "",
    transportLossAllMessage: "",
    transportLossAllStatus: "",
    ongoingDeliverys: [],
    pastDeliverys: [],
    orders: [],
    feedbacks: [],
    transLossAll: [],
    checkLists: [],
    tanks: [],
    justify: [],
    willSendCount: 0,
    transFormOfflineDatas: [],
    feedbackOptions: [],
    drivers: [],
    vehicles: [],
    message: "",
    responseStatus: "",
  },
};

export const reducers = combineReducers({
  user: userReducer,
  data: dataReducer,
});

export type AppState = ReturnType<typeof reducers>;
