import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Driver } from "../../models/Driver";
import { useTranslation } from "react-i18next";
import { connect } from "../../data/connect";
import * as selectors from "../../data/selectors";
import { refreshDriversData } from "../../data/data/data.actions";

interface OwnProps {
  onDismissModal: () => void;
  driver_id: number;
}

interface StateProps {
  driver: Driver;
  isLoadingDrivers: boolean;
}

interface DispatchProps {
  refreshDriversData: typeof refreshDriversData;
}

const DriverDetail: React.FC<OwnProps & StateProps & DispatchProps> = ({
  onDismissModal,
  refreshDriversData,
  isLoadingDrivers,
  driver_id,
  driver,
}) => {
  const [t, i18n] = useTranslation("common");

  console.log(driver);

  useEffect(() => {
    console.log(!driver);
    if (!driver) {
      refreshDriversData([driver_id], false);
    }
  }, [driver]);

  // if (!driver) {
  //   return (
  //     <>
  //       <IonHeader>
  //         <IonToolbar>
  //           <IonTitle>{t("modal_detail_crew.title")}</IonTitle>
  //           <IonButtons slot="end">
  //             <IonButton onClick={onDismissModal}>
  //               <IonIcon icon={closeOutline} />
  //             </IonButton>
  //           </IonButtons>
  //         </IonToolbar>
  //       </IonHeader>
  //       <IonContent className="ion-padding">
  //         <IonText> Data not found</IonText>
  //       </IonContent>
  //     </>
  //   );
  // }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("modal_detail_crew.title")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismissModal}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {isLoadingDrivers && (
          <div className="spin">
            <IonSpinner name="bubbles" color="primary" />
          </div>
        )}
        {!isLoadingDrivers && !driver && (
          <IonContent className="ion-padding">
            <IonText> Data not found</IonText>
          </IonContent>
        )}
        {!isLoadingDrivers && driver && (
          <>
            <img
              src={
                String(driver.image_medium) === "" ||
                String(driver.image_medium) === "false"
                  ? "assets/img/default.jpg"
                  : `data:image/gif;base64,${driver.image_medium}`
              }
              alt="Ionic logo"
              style={{ width: 150, height: 150 }}
            />
            <div className="ion-padding-top" />
            <IonRow>
              <IonCol>
                <IonText>
                  <strong>{t("modal_detail_crew.nip")}</strong>
                </IonText>
              </IonCol>
              <IonCol>
                <IonText>{driver?.driver_code}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>
                  <strong>{t("modal_detail_crew.nama_lengkap")}</strong>
                </IonText>
              </IonCol>
              <IonCol>
                <IonText>{driver?.driver}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>
                  <strong>{t("modal_detail_crew.jabatan")}</strong>
                </IonText>
              </IonCol>
              <IonCol>
                <IonText>{driver?.position}</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>
                  <strong>{t("modal_detail_crew.klasifikasi")}</strong>
                </IonText>
              </IonCol>
              <IonCol>
                <IonText>{driver?.classification}KL</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>
                  <strong>{t("modal_detail_crew.jenis_sewa")}</strong>
                </IonText>
              </IonCol>
              <IonCol>
                <IonText>{driver?.contract_type}</IonText>
              </IonCol>
            </IonRow>
          </>
        )}
      </IonContent>
    </>
  );
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state, OwnProps) => ({
    driver: selectors.getDriverDetail(state, OwnProps),
    isLoadingDrivers: state.data.dataLoadingDrivers,
  }),
  mapDispatchToProps: { refreshDriversData },
  component: React.memo(DriverDetail),
});
