import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

const TimeAgo: React.FC<{ timestamp: string }> = ({ timestamp }) => {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: (number) => number + " second",
      ss: "%d seconds",
      m: "1 minute",
      mm: "%d minutes",
      h: "1 hour",
      hh: "%d hours",
      d: "1 day",
      dd: "%d days",
      M: "1 month",
      MM: "%d months",
      y: "1 year",
      yy: "%d years",
    },
  });

  // console.log(timestamp);
  const [timeAgo, setTimeAgo] = useState<string>(moment(timestamp).fromNow());

  const [, updateState] = useState<any>();
  const handleForceupdateMethod = useCallback(() => updateState({}), []);

  useEffect(() => {
    // Refresh the time-ago representation every minute
    // console.log(timestamp);
    const intervalId = setInterval(() => {
      setTimeAgo(moment(timestamp).fromNow());
    }, 10000); // 60,000 milliseconds = 1 minute

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, [timestamp, timeAgo]);

  useEffect(() => {
    setTimeAgo(moment(timestamp).fromNow());
  }, [timestamp]);

  return <span>{timeAgo} ago</span>;
};

export default TimeAgo;
