// import { Storage } from "@capacitor/storage";
import { Preferences } from "@capacitor/preferences";
import { LossFormDataOffline } from "../models/Transportloss";
import { User } from "../models/User";

const HAS_LOGGED_IN = "hasLoggedIn";
const USERDATA = "user_data";
const USERNAME = "user_name";
const DELIVERY = "deliverys";
const ONGOING_DELIVERY = "ongoing_deliverys";
const PAST_DELIVERY = "past_deliverys";
const FEEDBACK = "feedback";
const ORDER = "order";
const TANK_OPTIONS = "tank";
const JUSTIFY_DATA = "justify";
const TRANSPORTLOSS_ALL = "transport_loss_all";
const CHECK_LIST = "check_list";
const TRANSPORTLOSS_OFFLINE = "transport_loss_offline";
const OFFLINE_STACK = "offline_stack";
const DRIVER_DETAIL = "driver_detail";
const VEHICLE_DETAIL = "vehicle_detail";
const AUTHENTICATED = "authenticated";
const FEEDBACK_OPTION = "feedback_option";

interface StackOffline {
  feedback: any[];
  checklist: any[];
  transportLoss: any[];
  profile: any;
}

export const setIsAuthenticatedStorage = async (isAuthenticated: boolean) => {
  await Preferences.set({
    key: AUTHENTICATED,
    value: JSON.stringify(isAuthenticated),
  });
};

export const initIsAuthenticatedStorage = async () => {
  await Preferences.set({
    key: AUTHENTICATED,
    value: JSON.stringify(false),
  });
};

export const getIsAuthenticatedStorage = async () => {
  let dataStorage = undefined;
  const { value } = await Preferences.get({ key: AUTHENTICATED });
  dataStorage = value;

  if (value == null || value == "undefined") {
    await initIsAuthenticatedStorage();
    const { value } = await Preferences.get({ key: AUTHENTICATED });
    dataStorage = value;
  }

  return JSON.parse(dataStorage!);
};

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Preferences.set({
    key: HAS_LOGGED_IN,
    value: JSON.stringify(isLoggedIn),
  });
};

export const setUserData = async (data?: any) => {
  if (!data) {
    await Preferences.remove({ key: USERDATA });
  } else {
    await Preferences.set({ key: USERDATA, value: JSON.stringify(data) });
  }
};

export const getUserData = async () => {
  const { value } = await Preferences.get({ key: USERDATA });

  if (value == null || value == "undefined") return false;

  return JSON.parse(value);
};

export const setUsernameData = async (data?: any) => {
  if (!data) {
    await Preferences.remove({ key: USERNAME });
  } else {
    await Preferences.set({ key: USERNAME, value: JSON.stringify(data) });
  }
};

export const getUsernameData = async () => {
  let dataStorage = undefined;
  const { value } = await Preferences.get({ key: USERNAME });
  dataStorage = value;

  if (value == null || value == "undefined") {
    await initUsernameData();
    const { value } = await Preferences.get({ key: USERNAME });
    dataStorage = value;
  }

  return JSON.parse(dataStorage!);
};

export const initUsernameData = async () => {
  Preferences.set({ key: USERNAME, value: JSON.stringify("") });
};

export const setDeliverys = async (data: any) => {
  await Preferences.set({ key: DELIVERY, value: JSON.stringify(data) });
};

export const getStorageDeliverys = async () => {
  const { value } = await Preferences.get({ key: DELIVERY });

  if (value == null || value == "undefined") return;

  return JSON.parse(value);
};

//? For set/save data ongoing delivery to local storage
export const setStorageOngoingDeliverys = async (data: any) => {
  await Preferences.set({ key: ONGOING_DELIVERY, value: JSON.stringify(data) });
};

//? For get/pull data ongoing delivery from local storage
export const getStorageOngoingDeliverys = async () => {
  let storageData = undefined;
  const { value } = await Preferences.get({ key: ONGOING_DELIVERY });
  storageData = value;

  //? if value is null or undefined, this case usually happen when ongoing deliverys data in local storage is empty
  if (value === null || value === "undefined") {
    await initStorageOngoingDeliverys();
    const { value } = await Preferences.get({ key: ONGOING_DELIVERY });
    storageData = value;
  }

  return JSON.parse(storageData!);
};

//? For init/set/save the ongoing delivery local storage with empty data
export const initStorageOngoingDeliverys = async () => {
  await Preferences.set({
    key: ONGOING_DELIVERY,
    value: JSON.stringify({
      lastUpdateOngoingDelivery: "",
      ongoingDeliverys: [],
    }),
  });
};

//? For set/save past deliverys data to local storage
export const setStoragePastDeliverys = async (data: any) => {
  await Preferences.set({ key: PAST_DELIVERY, value: JSON.stringify(data) });
};

//? for get/pull past deliverys data from local storage
export const getStoragePastDeliverys = async () => {
  let storageData = undefined;
  const { value } = await Preferences.get({ key: PAST_DELIVERY });
  storageData = value;

  if (value === null || value === "undefined") {
    await initStoragePastDeliverys();
    const { value } = await Preferences.get({ key: PAST_DELIVERY });
    storageData = value;
  }

  return JSON.parse(storageData!);
};

export const initStoragePastDeliverys = async () => {
  await Preferences.set({
    key: PAST_DELIVERY,
    value: JSON.stringify({
      lastUpdatePastDelivery: "",
      pastDeliverys: [],
    }),
  });
};

export const setOrder = async (data: any) => {
  await Preferences.set({ key: ORDER, value: JSON.stringify(data) });
};

export const getStorageOrders = async () => {
  let dataStorage = undefined;
  const { value } = await Preferences.get({ key: ORDER });
  dataStorage = value;

  if (value == null || value == "undefined") {
    await initStorageOrders();
    const { value } = await Preferences.get({ key: ORDER });
    dataStorage = value;
  }

  return JSON.parse(dataStorage!);
};

export const initStorageOrders = async () => {
  await Preferences.set({
    key: ORDER,
    value: JSON.stringify({
      lastUpdateOrders: "",
      orders: [],
    }),
  });
};

export const setFeedback = async (data: any) => {
  await Preferences.set({ key: FEEDBACK, value: JSON.stringify(data) });
};

export const getStorageFeedbacks = async () => {
  let dataStorage = undefined;
  const { value } = await Preferences.get({ key: FEEDBACK });
  dataStorage = value;

  if (value == null || value == "undefined") {
    await initStorageFeedbacks();
    const { value } = await Preferences.get({ key: FEEDBACK });
    dataStorage = value;
  }

  return JSON.parse(dataStorage!);
};

export const initStorageFeedbacks = async () => {
  await Preferences.set({
    key: FEEDBACK,
    value: JSON.stringify({
      lastUpdateFeedbacks: "",
      feedbacks: [],
    }),
  });
};

export const setTankOptions = async (data: any) => {
  await Preferences.set({ key: TANK_OPTIONS, value: JSON.stringify(data) });
};

export const getStorageTankOptions = async () => {
  const { value } = await Preferences.get({ key: TANK_OPTIONS });

  if (value == null || value == "undefined") return;

  return JSON.parse(value);
};

export const setJustify = async (data: any) => {
  await Preferences.set({ key: JUSTIFY_DATA, value: JSON.stringify(data) });
};

export const getStorageJustify = async () => {
  const { value } = await Preferences.get({ key: JUSTIFY_DATA });

  if (value == null || value == "undefined") return;

  return JSON.parse(value);
};

export const setTransportLossAll = async (data: any) => {
  await Preferences.set({
    key: TRANSPORTLOSS_ALL,
    value: JSON.stringify(data),
  });
};

export const getStorageTransportLossAll = async () => {
  let dataStorage = undefined;
  const { value } = await Preferences.get({ key: TRANSPORTLOSS_ALL });
  dataStorage = value;

  if (value == null || value == "undefined") {
    await initStorageTransportLossAll();
    const { value } = await Preferences.get({ key: TRANSPORTLOSS_ALL });
    dataStorage = value;
  }

  return JSON.parse(dataStorage!);
};

export const initStorageTransportLossAll = async () => {
  Preferences.set({
    key: TRANSPORTLOSS_ALL,
    value: JSON.stringify({
      lastUpdateTransportLossAll: "",
      transLossAll: [],
    }),
  });
};

export const setTransportLossOffline = async (data: any) => {
  await Preferences.set({
    key: TRANSPORTLOSS_OFFLINE,
    value: JSON.stringify(data),
  });
};

export const getStorageTransportLossOffline = async () => {
  let storageData = undefined;
  const { value } = await Preferences.get({ key: TRANSPORTLOSS_OFFLINE });
  storageData = value;

  if (value == null || value == "undefined") {
    await initStorageTransportLossOffline();
    const { value } = await Preferences.get({ key: TRANSPORTLOSS_OFFLINE });
    storageData = value;
  }

  return JSON.parse(storageData!);
};

export const initStorageTransportLossOffline = async () => {
  await Preferences.set({
    key: TRANSPORTLOSS_OFFLINE,
    value: JSON.stringify({ transFormOfflineDatas: [] }),
  });
};

export const setFeedbackOfflineOptions = async (data: any) => {
  await Preferences.set({ key: FEEDBACK_OPTION, value: JSON.stringify(data) });
};

export const getStorageFeedbackOfflineOptions = async () => {
  const { value } = await Preferences.get({ key: FEEDBACK_OPTION });

  if (value == null || value == "undefined") return;

  return JSON.parse(value);
};

export const initStorageFeedbackOfflineOptions = async () => {
  Preferences.set({
    key: FEEDBACK_OPTION,
    value: JSON.stringify({
      feedbackOptions: [],
    }),
  });
};

export const updateStorageTransportLossOffline = async (updateData: any) => {
  const prevDatas = (await getStorageTransportLossOffline())
    .transFormOfflineDatas as LossFormDataOffline[];
  const userData = (await getUserData()) as User;
  const currentDatas = prevDatas.map((d) => {
    const newLolineIds = d.lolines_ids.map((lo) =>
      lo.lo_id === updateData.lolines_id1
        ? {
            ...lo,
            ...{
              lo_compartment: updateData.compartment,
              lo_measure_by: updateData.measure_by,
              vol_after: updateData.vol_after,
            },
          }
        : lo
    );
    if (
      d.shipment_id === updateData.shipment_id &&
      d.compartment === updateData.compartment
    )
      return {
        ...d,
        ...updateData,
        ...{ spbu: userData.user_name },
        ...{ lolines_ids: newLolineIds },
      } as LossFormDataOffline;
    else if (
      d.shipment_id === updateData.shipment_id &&
      d.compartment !== updateData.compartment
    )
      return { ...d, ...{ lolines_ids: newLolineIds } };
    else return d;
  });
  await setTransportLossOffline({ transFormOfflineDatas: currentDatas });
};

export const setChecklists = async (data: any) => {
  await Preferences.set({ key: CHECK_LIST, value: JSON.stringify(data) });
};

export const getStorageChecklists = async () => {
  const { value } = await Preferences.get({ key: CHECK_LIST });

  if (value == null || value == "undefined") return;

  return JSON.parse(value);
};

export const setDriverDetails = async (data: any) => {
  await Preferences.set({ key: DRIVER_DETAIL, value: JSON.stringify(data) });
};

export const getStorageDriverDetails = async () => {
  let storageData = undefined;
  const { value } = await Preferences.get({ key: DRIVER_DETAIL });
  storageData = value;

  if (value == null || value == "undefined") {
    await initStorageDriverDetails();
    const { value } = await Preferences.get({ key: DRIVER_DETAIL });
    storageData = value;
  }

  return JSON.parse(storageData!);
};

export const initStorageDriverDetails = async () => {
  Preferences.set({
    key: DRIVER_DETAIL,
    value: JSON.stringify({
      drivers: [],
    }),
  });
};

export const setVehicleDatails = async (data: any) => {
  await Preferences.set({ key: VEHICLE_DETAIL, value: JSON.stringify(data) });
};

export const getStorageVehicleDatails = async () => {
  let storageData = undefined;
  const { value } = await Preferences.get({ key: VEHICLE_DETAIL });
  storageData = value;

  if (value == null || value == "undefined") {
    await initStorageVehicleDetails();
    const { value } = await Preferences.get({ key: VEHICLE_DETAIL });
    storageData = value;
  }

  return JSON.parse(storageData!);
};

export const initStorageVehicleDetails = async () => {
  Preferences.set({
    key: VEHICLE_DETAIL,
    value: JSON.stringify({
      vehicles: [],
    }),
  });
};

export const saveStorageStack = async (param: string, data: any) => {
  let storageData = undefined;
  const { value } = await Preferences.get({ key: OFFLINE_STACK });
  storageData = value;

  if (value == null || value == "undefined") {
    await initStorageStack();
    const { value } = await Preferences.get({ key: OFFLINE_STACK });
    storageData = value;
  }

  const prevData = JSON.parse(storageData!) as StackOffline;
  Object.entries(prevData).forEach(([key, value]) => {
    if (key === param) {
      if (Array.isArray(value)) value.push(data);
      else value = data;
    }
  });

  await Preferences.set({
    key: OFFLINE_STACK,
    value: JSON.stringify(prevData),
  });
  console.log("successful to save into storage stack");
};

export const getStorageStack = async () => {
  let storageData = undefined;
  const { value } = await Preferences.get({ key: OFFLINE_STACK });
  storageData = value;

  if (value == null || value == "undefined") {
    await initStorageStack();
    const { value } = await Preferences.get({ key: OFFLINE_STACK });
    storageData = value;
  }

  return JSON.parse(storageData!) as StackOffline;
};

export const initStorageStack = async () => {
  Preferences.set({
    key: OFFLINE_STACK,
    value: JSON.stringify({
      checklist: [],
      transportLoss: [],
      feedback: [],
      profile: "",
    }),
  });
};
