import {
  getCheckLists,
  getDelivery,
  getDriverDetails,
  getFeedbacks,
  getJustify,
  getOfflineStackCount,
  getOrders,
  getTanks,
  getTransportLossAll,
  getVehicleDetails,
  sendCheckLists as sendCheckListsSync,
  sendFeedback as sendFeedbackSync,
  sendTransportLossFormData as sendTransportLossFormDataSync,
  sendOfflineStackData,
  getFeedbackOfflineOptions,
  cleanStorage,
  getOngoingDelivery,
  getPastDelivery,
} from "../sync";
import { ActionType } from "../../util/types";
import { DataState } from "./data.state";
import { getLossFormOfflineData } from "../sync";
import {
  getUserData,
  getStorageDeliverys,
  getStorageTransportLossOffline,
  updateStorageTransportLossOffline,
  getStoragePastDeliverys,
  getUsernameData,
  setUsernameData,
} from "../storage";
//import { useHistory } from 'react-router';

export const cleanDataStrg = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(
    setOngoingDeliveryData({
      lastUpdateOngoingDelivery: "",
      ongoingDeliverys: [],
    } as Partial<DataState>)
  );
  dispatch(
    setPastDeliveryData({
      lastUpdatePastDelivery: "",
      pastDeliverys: [],
    } as Partial<DataState>)
  );
  dispatch(
    setOrderData({
      lastUpdateOrders: "",
      orders: [],
    } as Partial<DataState>)
  );
  dispatch(
    setFeedbackData({
      lastUpdateFeedbacks: "",
      feedbacks: [],
    } as Partial<DataState>)
  );
  dispatch(
    setTransLossData({
      lastUpdateTransportLossAll: "",
      transLossAll: [],
    } as Partial<DataState>)
  );
  dispatch(setFeedbackOptions({ feedbackOptions: [] } as Partial<DataState>));
  dispatch(setDriverDetails({ drivers: [] } as Partial<DataState>));
  dispatch(setVehicleDetails({ vehicles: [] } as Partial<DataState>));
  dispatch(setCheckListData({ checkLists: [] } as Partial<DataState>));
  dispatch(setTankData({ tanks: [] } as Partial<DataState>));
  dispatch(setJustifyData({ justify: [] } as Partial<DataState>));
  dispatch(
    setLossFormDataOffline({
      transFormOfflineDatas: [],
    } as Partial<DataState>)
  );
};

export const loadData = () => async (dispatch: React.Dispatch<any>) => {
  //? Untuk mengubah loading semua page global true
  dispatch(setChecklistLoaded(null));
  dispatch(setJustifyLoaded(null));
  dispatch(setTankLoaded(null));
  dispatch(setOngoingDeliverysLoaded(null));
  dispatch(setPastDeliverysLoaded(null));
  dispatch(setOrderLoaded(null));
  dispatch(setFeedbackLoaded(null));
  dispatch(setTransportLossAllLoaded(null));

  /* if change user --> clean all storage */
  let useStorage = true;
  const userDataStrg = await getUserData();
  // const deliveryDataStrg = await getStoragePastDeliverys();
  const usernameDataStrg = await getUsernameData();
  // console.log(usernameDataStrg);
  // console.log(userDataStrg);

  dispatch(setLoadingOngoingDeliverys(true));
  const { ongoingDelivery, ongoingDeliveryResponse } = await getOngoingDelivery(
    useStorage
  );

  if (!!ongoingDelivery && ongoingDelivery.ongoingDeliverys.length > 0) {
    dispatch(setOngoingDeliveryData(ongoingDelivery));
    dispatch(
      setOngoingDeliverysMessage(ongoingDeliveryResponse.ongoingDeliveryMessage)
    );
    dispatch(
      setOngoingDeliverysStatus(ongoingDeliveryResponse.ongoingDeliveryStatus)
    );
    dispatch(setOngoingDeliverysLoaded(true));
  }
  dispatch(setLoadingOngoingDeliverys(false));

  dispatch(setLoadingPastDeliverys(true));
  const { pastDelivery, pastDeliveryResponse } = await getPastDelivery(
    useStorage
  );
  if (!!pastDelivery && pastDelivery.pastDeliverys.length > 0) {
    dispatch(setPastDeliveryData(pastDelivery));
    dispatch(setPastDeliverysStatus(pastDeliveryResponse.pastDeliveryStatus));
    dispatch(setPastDeliverysMessage(pastDeliveryResponse.pastDeliveryMessage));
    dispatch(setPastDeliverysLoaded(true));
  }
  dispatch(setLoadingPastDeliverys(false));

  dispatch(setLoadingDrivers(true));
  const driverDetails = await getDriverDetails([], useStorage);
  // console.log(driverDetails);
  if (!!driverDetails) {
    dispatch(setDriverDetails(driverDetails));
  }
  dispatch(setLoadingDrivers(false));

  dispatch(setLoadingVehicles(true));
  const vehicleDetails = await getVehicleDetails([], useStorage);
  if (!!vehicleDetails) {
    dispatch(setVehicleDetails(vehicleDetails));
  }
  dispatch(setLoadingVehicles(false));

  dispatch(setLoadingOrder(true));
  const { order, orderResponse } = await getOrders(useStorage);
  // console.log(order);
  if (!!order && order.orders.length > 0) {
    dispatch(setOrderData(order));
    dispatch(setOrderMessage(orderResponse.orderMessage));
    dispatch(setOrderStatus(orderResponse.orderStatus));
    dispatch(setOrderLoaded(true));
  }
  dispatch(setLoadingOrder(false));

  dispatch(setLoadingFeedback(true));
  const { feedbacks, feedbacksResponse } = await getFeedbacks(useStorage);
  if (!!feedbacks && feedbacks.feedbacks.length > 0) {
    dispatch(setFeedbackMessage(feedbacksResponse.feedbackMessage));
    dispatch(setFeedbackStatus(feedbacksResponse.feedbackStatus));
    dispatch(setFeedbackData(feedbacks));
    dispatch(setFeedbackLoaded(true));
  }
  dispatch(setLoadingFeedback(false));

  dispatch(setLoadingTransportLossAll(true));
  const { transportlossAll, transportLossAllResponse } =
    await getTransportLossAll(useStorage);

  if (!!transportlossAll && transportlossAll.transLossAll.length > 0) {
    dispatch(setTransLossData(transportlossAll));
    dispatch(
      setTransportLossAllMessage(
        transportLossAllResponse.transportLossAllMessage
      )
    );
    dispatch(
      setTransportLossAllStatus(transportLossAllResponse.transportLossAllStatus)
    );
    dispatch(setTransportLossAllLoaded(true));
  }
  dispatch(setLoadingTransportLossAll(false));

  dispatch(setLoadingFeedbackOptions(true));
  const feedbackOptions = await getFeedbackOfflineOptions(useStorage);
  dispatch(setFeedbackOptions(feedbackOptions));
  dispatch(setLoadingFeedbackOptions(false));

  dispatch(setLoadingLossFormOfflineData(true));
  const lossFormDataOffline = await getLossFormOfflineData([], useStorage);
  dispatch(setLossFormDataOffline(lossFormDataOffline));
  dispatch(setLoadingLossFormOfflineData(false));

  dispatch(setLoadingChecklist(true));
  const { checkList, checklistResponse } = await getCheckLists(false);
  // if (!!checkList && checkList.checkLists.length > 0) {
  dispatch(setCheckListData(checkList));
  dispatch(setChecklistLoaded(true));
  // }
  dispatch(setLoadingChecklist(false));

  dispatch(setLoadingTank(true));
  const { tank, tankResponse } = await getTanks(false);
  // if (!!tank && tank.tanks.length > 0) {
  dispatch(setTankData(tank));
  dispatch(setTankLoaded(true));
  // }
  dispatch(setLoadingTank(false));

  dispatch(setLoadingJustify(true));
  const { justify, justifyResponse } = await getJustify(false);
  // if (!!justify && justify.justify.length > 0) {
  dispatch(setJustifyData(justify));
  dispatch(setJustifyLoaded(true));
  // }
  dispatch(setLoadingJustify(false));

  //? Untuk mengubah loading semua page global false
  // dispatch(setLoading(false));
};

export const loadDataLogin = () => async (dispatch: React.Dispatch<any>) => {
  let useStorage = true;
  dispatch(setChecklistLoaded(null));
  dispatch(setJustifyLoaded(null));
  dispatch(setTankLoaded(null));
  dispatch(setOngoingDeliverysLoaded(null));
  dispatch(setPastDeliverysLoaded(null));
  dispatch(setOrderLoaded(null));
  dispatch(setFeedbackLoaded(null));
  dispatch(setTransportLossAllLoaded(null));

  const userDataStrg = await getUserData();
  // const deliveryDataStrg = await getStoragePastDeliverys();
  const usernameDataStrg = await getUsernameData();

  if (userDataStrg && usernameDataStrg) {
    if (!!usernameDataStrg && userDataStrg.user_name != usernameDataStrg) {
      console.log("Clean All Storage ...");
      const clnSts = await cleanStorage();
      if (clnSts) {
        cleanDataStrg();
      }
    }
  }
  setUsernameData(userDataStrg.user_name);

  // dispatch(setLoadingOngoingDeliverys(true));
  const { ongoingDelivery, ongoingDeliveryResponse } = await getOngoingDelivery(
    useStorage
  );

  if (!!ongoingDelivery) {
    dispatch(setOngoingDeliveryData(ongoingDelivery));
    dispatch(
      setOngoingDeliverysMessage(ongoingDeliveryResponse.ongoingDeliveryMessage)
    );
    dispatch(
      setOngoingDeliverysStatus(ongoingDeliveryResponse.ongoingDeliveryStatus)
    );
  }
  // dispatch(setLoadingOngoingDeliverys(false));

  // dispatch(setLoadingPastDeliverys(true));
  const { pastDelivery, pastDeliveryResponse } = await getPastDelivery(
    useStorage
  );
  if (!!pastDelivery) {
    dispatch(setPastDeliveryData(pastDelivery));
    dispatch(setPastDeliverysStatus(pastDeliveryResponse.pastDeliveryStatus));
    dispatch(setPastDeliverysMessage(pastDeliveryResponse.pastDeliveryMessage));
  }
  // dispatch(setLoadingPastDeliverys(false));

  // dispatch(setLoadingDrivers(true));
  const driverDetails = await getDriverDetails([], useStorage);
  // console.log(driverDetails);
  if (!!driverDetails) {
    dispatch(setDriverDetails(driverDetails));
  }
  // dispatch(setLoadingDrivers(false));

  // dispatch(setLoadingVehicles(true));
  const vehicleDetails = await getVehicleDetails([], useStorage);
  if (!!vehicleDetails) {
    dispatch(setVehicleDetails(vehicleDetails));
  }
  // dispatch(setLoadingVehicles(false));

  // dispatch(setLoadingOrder(true));
  const { order, orderResponse } = await getOrders(useStorage);
  if (!!order) {
    dispatch(setOrderData(order));
    dispatch(setOrderMessage(orderResponse.orderMessage));
    dispatch(setOrderStatus(orderResponse.orderStatus));
  }
  // dispatch(setLoadingOrder(false));

  // dispatch(setLoadingFeedback(true));
  const { feedbacks, feedbacksResponse } = await getFeedbacks(useStorage);
  if (!!feedbacks) {
    dispatch(setFeedbackMessage(feedbacksResponse.feedbackMessage));
    dispatch(setFeedbackStatus(feedbacksResponse.feedbackStatus));
    dispatch(setFeedbackData(feedbacks));
  }
  // dispatch(setLoadingFeedback(false));

  // dispatch(setLoadingTransportLossAll(true));
  const { transportlossAll, transportLossAllResponse } =
    await getTransportLossAll(useStorage);

  if (!!transportlossAll) {
    dispatch(setTransLossData(transportlossAll));
    dispatch(
      setTransportLossAllMessage(
        transportLossAllResponse.transportLossAllMessage
      )
    );
    dispatch(
      setTransportLossAllStatus(transportLossAllResponse.transportLossAllStatus)
    );
  }
  // dispatch(setLoadingTransportLossAll(false));

  // dispatch(setLoadingFeedbackOptions(true));
  const feedbackOptions = await getFeedbackOfflineOptions(useStorage);
  dispatch(setFeedbackOptions(feedbackOptions));
  // dispatch(setLoadingFeedbackOptions(false));

  // dispatch(setLoadingLossFormOfflineData(true));
  const lossFormDataOffline = await getLossFormOfflineData([], useStorage);
  dispatch(setLossFormDataOffline(lossFormDataOffline));
  // dispatch(setLoadingLossFormOfflineData(false));

  // dispatch(setLoadingChecklist(true));
  const { checkList, checklistResponse } = await getCheckLists(useStorage);
  dispatch(setCheckListData(checkList));
  // dispatch(setChecklistLoaded(true));
  // dispatch(setLoadingChecklist(false));

  // dispatch(setLoadingTank(true));
  const { tank, tankResponse } = await getTanks(useStorage);
  dispatch(setTankData(tank));
  // dispatch(setTankLoaded(true));
  // dispatch(setLoadingTank(false));

  // dispatch(setLoadingJustify(true));
  const justify = await getJustify(useStorage);
  dispatch(setJustifyData(justify));
  // dispatch(setJustifyLoaded(true));
  // dispatch(setLoadingJustify(false));

  // console.log("RUN");
};

export const refreshFeedbackOptions =
  (useStorage: boolean) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoadingFeedbackOptions(true));

    const feedbackOptions = await getFeedbackOfflineOptions(useStorage);
    dispatch(setFeedbackOptions(feedbackOptions));

    dispatch(setLoadingFeedbackOptions(false));
  };

export const refreshDriversData =
  (driverIDs: Array<any>, useStorage: boolean) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoadingDrivers(true));

    const driverDetails = await getDriverDetails(driverIDs, useStorage);
    dispatch(setDriverDetails(driverDetails));

    dispatch(setLoadingDrivers(false));
  };

export const refreshVehiclesData =
  (vehicleIDs: Array<any>, useStorage: boolean) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoadingVehicles(true));

    const vehicleDetails = await getVehicleDetails(vehicleIDs, useStorage);
    dispatch(setVehicleDetails(vehicleDetails));

    dispatch(setLoadingVehicles(false));
  };

export const refreshTankData =
  (useStorage: boolean) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoadingTank(true));
    const { tank, tankResponse } = await getTanks(useStorage);
    dispatch(setTankData(tank));
    dispatch(setLoadingTank(false));
    dispatch(setTankLoaded(true));
  };

export const refreshJustifyData =
  (useStorage: boolean) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoadingJustify(true));
    const { justify, justifyResponse } = await getJustify(useStorage);
    dispatch(setJustifyData(justify));
    dispatch(setLoadingJustify(false));
    dispatch(setJustifyLoaded(true));
  };

export const refreshChecklistData =
  (useStorage: boolean) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoadingChecklist(true));
    const { checkList, checklistResponse } = await getCheckLists(useStorage);
    dispatch(setCheckListData(checkList));
    dispatch(setLoadingChecklist(false));
    dispatch(setChecklistLoaded(true));
  };

export const refreshLossFormOfflineData =
  (shipIDs: Array<any>, useStorage: boolean) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoadingLossFormOfflineData(true));
    const lossFormDataOffline = await getLossFormOfflineData(
      shipIDs,
      useStorage
    );
    dispatch(setLossFormDataOffline(lossFormDataOffline));
    dispatch(setLoadingLossFormOfflineData(false));
  };

export const refreshDeliverysData =
  (useStorage: boolean) => async (dispatch: React.Dispatch<any>) => {
    // dispatch(setLoadingDeliverys(true));

    // console.log(useStorage);
    // const { delivery, shipIds, driverAssistantIDs, vehicleIDs } =
    const { delivery, ongoingDeliveryResponse, pastDeliveryResponse } =
      await getDelivery(useStorage);

    dispatch(setDeliveryData(delivery.delivery));
    dispatch(
      setOngoingDeliverysMessage(ongoingDeliveryResponse.ongoingDeliveryMessage)
    );
    dispatch(
      setOngoingDeliverysStatus(ongoingDeliveryResponse.ongoingDeliveryStatus)
    );
    dispatch(setPastDeliverysMessage(pastDeliveryResponse.pastDeliveryMessage));
    dispatch(setPastDeliverysStatus(pastDeliveryResponse.pastDeliveryStatus));

    // const lossFormDataOffline = await getLossFormOffineData(
    //   delivery.shipIds,
    //   useStorage
    // );
    // dispatch(setLossFormDataOffline(lossFormDataOffline));

    // const driverDetails = await getDriverDetails(
    //   delivery.driverAssistantIDs,
    //   useStorage
    // );
    // dispatch(setDriverDetails(driverDetails));

    // const vehicleDetails = await getVehicleDetails(
    //   delivery.vehicleIDs,
    //   useStorage
    // );
    // dispatch(setVehicleDetails(vehicleDetails));

    // dispatch(setLoadingDeliverys(false));
    // dispatch(setDeliverysLoaded(true));
  };

export const refreshOngoingDeliverysData =
  (useStorage: boolean) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoadingOngoingDeliverys(true));

    // const { delivery, shipIds, driverAssistantIDs, vehicleIDs } =
    const { ongoingDelivery, ongoingDeliveryResponse } =
      await getOngoingDelivery(useStorage);
    const ongoingDeliveryData = ongoingDelivery;
    dispatch(setOngoingDeliveryData(ongoingDeliveryData));
    dispatch(
      setOngoingDeliverysMessage(ongoingDeliveryResponse.ongoingDeliveryMessage)
    );
    dispatch(
      setOngoingDeliverysStatus(ongoingDeliveryResponse.ongoingDeliveryStatus)
    );

    dispatch(setLoadingOngoingDeliverys(false));
    dispatch(setOngoingDeliverysLoaded(true));
  };

export const refreshPastDeliverysData =
  (useStorage: boolean) => async (dispatch: React.Dispatch<any>) => {
    dispatch(setLoadingPastDeliverys(true));

    // const { delivery, shipIdsPast, driverAssistantIDsPast, vehicleIDsPast } =
    const { pastDelivery, pastDeliveryResponse } = await getPastDelivery(
      useStorage
    );
    // console.log(pastDelivery);
    const deliveryData = pastDelivery;
    dispatch(setPastDeliveryData(pastDelivery));
    dispatch(setPastDeliverysMessage(pastDeliveryResponse.pastDeliveryMessage));
    dispatch(setPastDeliverysStatus(pastDeliveryResponse.pastDeliveryStatus));

    dispatch(setLoadingPastDeliverys(false));
    dispatch(setPastDeliverysLoaded(true));
  };

export const refreshOrders = () => async (dispatch: React.Dispatch<any>) => {
  // dispatch(setLoading(true));
  dispatch(setLoadingOrder(true));

  const { order, orderResponse } = await getOrders(false);
  // console.log(order);
  dispatch(setOrderData(order));
  dispatch(setOrderMessage(orderResponse.orderMessage));
  dispatch(setOrderStatus(orderResponse.orderStatus));
  // dispatch(setLoading(false));
  dispatch(setOrderLoaded(true));
  dispatch(setLoadingOrder(false));
};

export const refreshTransportLossAll =
  () => async (dispatch: React.Dispatch<any>) => {
    const { transportlossAll, transportLossAllResponse } =
      await getTransportLossAll(false);
    dispatch(setTransLossData(transportlossAll));
  };

export const refreshTransportLossAll2 =
  () => async (dispatch: React.Dispatch<any>) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingTransportLossAll(true));

    const { transportlossAll, transportLossAllResponse } =
      await getTransportLossAll(false);
    dispatch(setTransLossData(transportlossAll));
    dispatch(
      setTransportLossAllMessage(
        transportLossAllResponse.transportLossAllMessage
      )
    );
    dispatch(
      setTransportLossAllStatus(transportLossAllResponse.transportLossAllStatus)
    );
    // dispatch(setLoading(false));
    dispatch(setTransportLossAllLoaded(true));
    dispatch(setLoadingTransportLossAll(false));
  };

export const refreshFeedbacks = () => async (dispatch: React.Dispatch<any>) => {
  const { feedbacks, feedbacksResponse } = await getFeedbacks(false);
  dispatch(setFeedbackMessage(feedbacksResponse.feedbackMessage));
  dispatch(setFeedbackStatus(feedbacksResponse.feedbackStatus));
  dispatch(setFeedbackData(feedbacks));
};

export const refreshFeedbacks2 =
  () => async (dispatch: React.Dispatch<any>) => {
    // dispatch(setLoading(true));
    dispatch(setLoadingFeedback(true));

    const { feedbacks, feedbacksResponse } = await getFeedbacks(false);
    dispatch(setFeedbackMessage(feedbacksResponse.feedbackMessage));
    dispatch(setFeedbackStatus(feedbacksResponse.feedbackStatus));
    dispatch(setFeedbackData(feedbacks));

    // dispatch(setLoading(false));
    dispatch(setFeedbackLoaded(true));
    dispatch(setLoadingFeedback(false));
  };

export const setResInfoAfterSend =
  (msg: string, responseStatus: string) =>
  async (dispatch: React.Dispatch<any>) => {
    const count = await getOfflineStackCount();
    dispatch(setWillSendCount(count));
    dispatch(setServerMessage(msg));
    dispatch(setServerResStatus(responseStatus));
  };

export const sendOfflineData = () => async (dispatch: React.Dispatch<any>) => {
  await sendOfflineStackData();
  const count = await getOfflineStackCount();
  dispatch(setWillSendCount(count));
};

export const refreshWillSendCount =
  () => async (dispatch: React.Dispatch<any>) => {
    const count = await getOfflineStackCount();
    dispatch(setWillSendCount(count));
  };

export const updateTransportLossOfflineData =
  (data: any) => async (dispatch: React.Dispatch<any>) => {
    await updateStorageTransportLossOffline(data);
    const lossFormDataOffline = await getStorageTransportLossOffline();
    dispatch(setLossFormDataOffline(lossFormDataOffline));
  };

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-loading",
    isLoading,
  } as const);

export const setLoadingOngoingDeliverys = (
  isLoadingOngoingDeliverys: boolean
) =>
  ({
    type: "set-ongoing-delivery-loading",
    isLoadingOngoingDeliverys,
  } as const);

export const setLoadingPastDeliverys = (isLoadingPastDeliverys: boolean) =>
  ({
    type: "set-past-delivery-loading",
    isLoadingPastDeliverys,
  } as const);

export const setLoadingFeedbackOptions = (isLoadingFeedbackOptions: boolean) =>
  ({
    type: "set-feedback-options-loading",
    isLoadingFeedbackOptions,
  } as const);

export const setLoadingDrivers = (isLoadingDrivers: boolean) =>
  ({
    type: "set-driver-loading",
    isLoadingDrivers,
  } as const);

export const setLoadingVehicles = (isLoadingVehicles: boolean) =>
  ({
    type: "set-vehicle-loading",
    isLoadingVehicles,
  } as const);

export const setLoadingLossFormOfflineData = (
  isLoadingLossFormOfflineData: boolean
) =>
  ({
    type: "set-loss-form-offline-data-loading",
    isLoadingLossFormOfflineData,
  } as const);

export const setLoadingChecklist = (isLoadingChecklist: boolean) =>
  ({
    type: "set-checklist-loading",
    isLoadingChecklist,
  } as const);

export const setLoadingTank = (isLoadingTank: boolean) =>
  ({
    type: "set-tank-loading",
    isLoadingTank,
  } as const);

export const setLoadingJustify = (isLoadingJustify: boolean) =>
  ({
    type: "set-justify-loading",
    isLoadingJustify,
  } as const);

export const setLoadingOrder = (isLoadingOrder: boolean) =>
  ({
    type: "set-order-loading",
    isLoadingOrder,
  } as const);

export const setLoadingFeedback = (isLoadingFeedback: boolean) =>
  ({
    type: "set-feedback-loading",
    isLoadingFeedback,
  } as const);

export const setLoadingTransportLossAll = (
  isLoadingTransportLossAll: boolean
) =>
  ({
    type: "set-transport-loss-all-loading",
    isLoadingTransportLossAll,
  } as const);

export const setJustifyLoaded = (_isJustifyLoaded: true | null) =>
  ({
    type: "set-justify-loaded",
    _isJustifyLoaded,
  } as const);

export const setTankLoaded = (_isTankLoaded: true | null) =>
  ({
    type: "set-tank-loaded",
    _isTankLoaded,
  } as const);

export const setChecklistLoaded = (_isChecklistLoaded: true | null) =>
  ({
    type: "set-checklist-loaded",
    _isChecklistLoaded,
  } as const);

export const setOngoingDeliverysLoaded = (
  _isOngoingDeliverysLoaded: true | null
) =>
  ({
    type: "set-ongoing-delivery-loaded",
    _isOngoingDeliverysLoaded,
  } as const);

export const setPastDeliverysLoaded = (_isPastDeliverysLoaded: true | null) =>
  ({
    type: "set-past-delivery-loaded",
    _isPastDeliverysLoaded,
  } as const);

export const setOrderLoaded = (_isOrderLoaded: true | null) =>
  ({
    type: "set-order-loaded",
    _isOrderLoaded,
  } as const);

export const setFeedbackLoaded = (_isFeedbackLoaded: true | null) =>
  ({
    type: "set-feedback-loaded",
    _isFeedbackLoaded,
  } as const);

export const setTransportLossAllLoaded = (
  _isTransportLossAllLoaded: true | null
) =>
  ({
    type: "set-transport-loss-all-loaded",
    _isTransportLossAllLoaded,
  } as const);

export const setOngoingDeliverysMessage = (ongoingDeliverysMessage: string) =>
  ({
    type: "set-ongoing-deliverys-message",
    ongoingDeliverysMessage,
  } as const);

export const setOngoingDeliverysStatus = (ongoingDeliverysStatus: string) =>
  ({
    type: "set-ongoing-deliverys-status",
    ongoingDeliverysStatus,
  } as const);

export const setPastDeliverysMessage = (pastDeliverysMessage: string) =>
  ({
    type: "set-past-deliverys-message",
    pastDeliverysMessage,
  } as const);

export const setPastDeliverysStatus = (pastDeliverysStatus: string) =>
  ({
    type: "set-past-deliverys-status",
    pastDeliverysStatus,
  } as const);

export const setOrderMessage = (orderMessage: string) =>
  ({
    type: "set-order-message",
    orderMessage,
  } as const);

export const setOrderStatus = (orderStatus: string) =>
  ({
    type: "set-order-status",
    orderStatus,
  } as const);

export const setFeedbackMessage = (feedbackMessage: string) =>
  ({
    type: "set-feedback-message",
    feedbackMessage,
  } as const);

export const setFeedbackStatus = (feedbackStatus: string) =>
  ({
    type: "set-feedback-status",
    feedbackStatus,
  } as const);

export const setTransportLossAllMessage = (transportLossAllMessage: string) =>
  ({
    type: "set-transport-loss-all-message",
    transportLossAllMessage,
  } as const);

export const setTransportLossAllStatus = (transportLossAllStatus: string) =>
  ({
    type: "set-transport-loss-all-status",
    transportLossAllStatus,
  } as const);

export const setServerMessage = (msg: string) =>
  ({
    type: "set-server-message",
    msg,
  } as const);

export const setServerResStatus = (status: string) =>
  ({
    type: "set-server-res-status",
    status,
  } as const);

export const setDeliveryData = (data: Partial<DataState>) =>
  ({
    type: "set-delivery-data",
    data,
  } as const);

export const setOngoingDeliveryData = (data: Partial<DataState>) =>
  ({
    type: "set-ongoing-delivery-data",
    data,
  } as const);

export const setPastDeliveryData = (data: Partial<DataState>) =>
  ({
    type: "set-past-delivery-data",
    data,
  } as const);

export const setOrderData = (data: Partial<DataState>) =>
  ({
    type: "set-order-data",
    data,
  } as const);

export const setFeedbackData = (data: Partial<DataState>) =>
  ({
    type: "set-feedback-data",
    data,
  } as const);

export const setTransLossData = (data: Partial<DataState>) =>
  ({
    type: "set-transloss-data",
    data,
  } as const);

export const setCheckListData = (data: Partial<DataState>) =>
  ({
    type: "set-checklist-data",
    data,
  } as const);

export const setJustifyData = (data: Partial<DataState>) =>
  ({
    type: "set-justify-data",
    data,
  } as const);

export const setTankData = (data: Partial<DataState>) =>
  ({
    type: "set-tank-data",
    data,
  } as const);

export const setSearchText = (searchText?: string) =>
  ({
    type: "set-search-text",
    searchText,
  } as const);

export const setWillSendCount = (count: number) => {
  console.log(`notification : ${count}`);
  return {
    type: "set-will-send-count",
    count,
  } as const;
};

export const setLossFormDataOffline = (data: Partial<DataState>) =>
  ({
    type: "set-lossform-offline",
    data,
  } as const);

export const setFeedbackOptions = (data: Partial<DataState>) =>
  ({
    type: "set-feedback-option",
    data,
  } as const);

export const setDriverDetails = (data: Partial<DataState>) =>
  ({
    type: "set-driver-detail",
    data,
  } as const);

export const setVehicleDetails = (data: Partial<DataState>) =>
  ({
    type: "set-vehicle-detail",
    data,
  } as const);

export type DataActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setLoadingOngoingDeliverys>
  | ActionType<typeof setLoadingPastDeliverys>
  | ActionType<typeof setLoadingFeedbackOptions>
  | ActionType<typeof setLoadingDrivers>
  | ActionType<typeof setLoadingVehicles>
  | ActionType<typeof setLoadingLossFormOfflineData>
  | ActionType<typeof setLoadingChecklist>
  | ActionType<typeof setLoadingTank>
  | ActionType<typeof setLoadingJustify>
  | ActionType<typeof setLoadingOrder>
  | ActionType<typeof setLoadingFeedback>
  | ActionType<typeof setLoadingTransportLossAll>
  | ActionType<typeof setJustifyLoaded>
  | ActionType<typeof setTankLoaded>
  | ActionType<typeof setChecklistLoaded>
  | ActionType<typeof setOngoingDeliverysLoaded>
  | ActionType<typeof setPastDeliverysLoaded>
  | ActionType<typeof setOrderLoaded>
  | ActionType<typeof setFeedbackLoaded>
  | ActionType<typeof setTransportLossAllLoaded>
  | ActionType<typeof setOngoingDeliverysMessage>
  | ActionType<typeof setOngoingDeliverysStatus>
  | ActionType<typeof setPastDeliverysMessage>
  | ActionType<typeof setPastDeliverysStatus>
  | ActionType<typeof setOrderMessage>
  | ActionType<typeof setOrderStatus>
  | ActionType<typeof setFeedbackMessage>
  | ActionType<typeof setFeedbackStatus>
  | ActionType<typeof setTransportLossAllMessage>
  | ActionType<typeof setTransportLossAllStatus>
  | ActionType<typeof setDeliveryData>
  | ActionType<typeof setOngoingDeliveryData>
  | ActionType<typeof setPastDeliveryData>
  | ActionType<typeof setOrderData>
  | ActionType<typeof setFeedbackData>
  | ActionType<typeof setTransLossData>
  | ActionType<typeof setCheckListData>
  | ActionType<typeof setTankData>
  | ActionType<typeof setJustifyData>
  | ActionType<typeof setSearchText>
  | ActionType<typeof setWillSendCount>
  | ActionType<typeof setLossFormDataOffline>
  | ActionType<typeof setDriverDetails>
  | ActionType<typeof setVehicleDetails>
  | ActionType<typeof setServerMessage>
  | ActionType<typeof setServerResStatus>
  | ActionType<typeof setFeedbackOptions>;
