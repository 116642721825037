import React, { useState } from "react";
import {
  IonIcon,
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonSpinner,
  IonCard,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonCardContent,
  IonFooter,
} from "@ionic/react";
import "./Login.scss";
import {
  setIsAuthenticated,
  setUsername,
  setUserData,
  setLoading,
  logInUser,
} from "../data/user/user.actions";
import { authenticateFunc } from "../data/api";
/*import { doAuthenticate, getApps } from '../data/api';*/
import {
  setUserData as setUserDataStorage,
  setUsernameData,
} from "../data/storage";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import {
  eyeOffOutline,
  eyeOutline,
  personOutline,
  lockClosedOutline,
  enterOutline,
  warningOutline,
} from "ionicons/icons";
import { loadData, loadDataLogin } from "../data/data/data.actions";
import { useTranslation } from "react-i18next";
import { setIsAuthenticatedStorage } from "../data/storage";
import { menuController } from "@ionic/core";
import CryptoJS from "crypto-js";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setLoading: typeof setLoading;
  setUsername: typeof setUsername;
  setIsAuthenticated: typeof setIsAuthenticated;
  setUserData: typeof setUserData;
  loadDataLogin: typeof loadDataLogin;
  logInUser: typeof logInUser;
}

interface StateProps {
  isLoading: boolean;
}

interface LoginProps extends OwnProps, StateProps, DispatchProps {}

const Login: React.FC<LoginProps> = ({
  isLoading,
  history,
  loadDataLogin,
  logInUser,
  setUsername: setUsernameAction,
  setIsAuthenticated,
  setUserData: setUserDataAction,
  setLoading,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [authErrorMessage, setAuthErrorMessage] = useState("");
  const [t, i18n] = useTranslation("common");

  function encrypt(msgString: any, key: any) {
    // msgString is expected to be Utf8 encoded
    var iv = CryptoJS.lib.WordArray.random(16);
    // console.log("iv", iv);
    var encrypted = CryptoJS.AES.encrypt(msgString, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  }

  // function decrypt(ciphertextStr:any, key:any) {
  //     var ciphertext = CryptoJS.enc.Base64.parse(ciphertextStr);

  //     // split IV and ciphertext
  //     var iv = ciphertext.clone();
  //     iv.sigBytes = 16;
  //     iv.clamp();
  //     ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  //     ciphertext.sigBytes -= 16;

  //     // decryption
  //     var decrypted = CryptoJS.AES.decrypt({ciphertext: ciphertext}, key, {
  //         iv: iv
  //     });
  //     return decrypted.toString(CryptoJS.enc.Utf8);
  // }

  useIonViewWillEnter(() => {
    menuController.enable(false);
  });

  useIonViewWillLeave(() => {
    menuController.enable(true);
  });

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!username) {
      setUsernameError(true);
    } else {
      setUsernameError(false);
    }
    if (!password) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (username && password) {
      setLoading(true);
      let key = CryptoJS.enc.Utf8.parse("1986063019900907");
      let usernameF = encrypt(username, key);
      let passwordF = encrypt(password, key);

      // console.log("Encrypted Username", usernameF);
      // console.log("Encrypted Password", passwordF);

      const formData = new FormData();
      formData.append("ulg", usernameF);
      formData.append("upd", passwordF);

      /*
      const appsData = await getApps(formData);
      console.log(appsData);

      if (!appsData) {
        setLoading(false);
        window.open('https://odi.smart-leaders.net/spbu', '_self')
      }

      const app_name = appsData?.name;
      console.log(app_name);
      
      if (app_name) {
        console.log(app_name);
        setLoading(false);
        window.open(app_name, '_self')
      }
      */

      const authenticateData = await authenticateFunc(formData);
      // console.log(userData);

      const userData = authenticateData?.data[0];

      console.log(authenticateData);
      console.log(authenticateData?.status === "S");
      if (authenticateData?.status === "S") {
        await setIsAuthenticated(true);
        setAuthError(false);
        await setIsAuthenticatedStorage(true);
        setUserDataAction(userData);
        setUserDataStorage(userData);
        await setUsernameAction(userData.user_name);
        await logInUser();
        await loadDataLogin();
        history.push("/tabs/delivery", { direction: "none" });
      } else {
        await setIsAuthenticated(false);
        setAuthError(true);
        !!authenticateData && setAuthErrorMessage(authenticateData?.desc_msg);
        history.push("/login", { direction: "none" });
      }
      setLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const checkAuthenticated = async () => {
  //   const isAuthenticated = await getIsAuthenticated();
  //   console.log(isAuthenticated);
  //   if (isAuthenticated) {
  //     history.push('/tabs/delivery', {direction: 'none'});
  //     loadData();
  //   }
  // }

  // useEffect(() => {
  //   checkAuthenticated();
  // }, []);
  // console.log("MASUK LOGIN");

  return (
    <IonPage id="login-page">
      <IonContent>
        <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
          <h4>{t("home.title")}</h4>
        </div>
        <form noValidate onSubmit={login}>
          <IonList>
            <IonItem className="login-input">
              <IonLabel>
                <IonIcon icon={personOutline}></IonIcon>
              </IonLabel>
              <IonInput
                name="ulg"
                type="text"
                value={username}
                placeholder="User name"
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => setUsername(e.detail.value!)}
                required
              ></IonInput>
            </IonItem>

            {formSubmitted && usernameError && (
              <IonText color="danger">
                <p className="ion-padding-start">Username is required</p>
              </IonText>
            )}

            <IonItem className="login-input">
              <IonLabel>
                <IonIcon icon={lockClosedOutline}></IonIcon>
              </IonLabel>
              <IonInput
                name="upd"
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder="Password"
                onIonChange={(e) => setPassword(e.detail.value!)}
              ></IonInput>
              <IonIcon
                icon={showPassword ? eyeOffOutline : eyeOutline}
                onClick={() => toggleShowPassword()}
              ></IonIcon>
            </IonItem>

            {formSubmitted && passwordError && (
              <IonText color="danger">
                <p className="ion-padding-start">Password is required</p>
              </IonText>
            )}

            {formSubmitted && authError && (
              <IonText color="danger">
                <p className="ion-padding-start">{authErrorMessage}</p>
              </IonText>
            )}
          </IonList>

          <IonRow>
            <IonCol className="ion-item-center">
              <IonButton type="submit" expand="block">
                {isLoading && <IonSpinner name="bubbles" color="light" />}
                <IonIcon icon={enterOutline}></IonIcon>&nbsp;&nbsp;
                {t("home.login")}
              </IonButton>
            </IonCol>
            {/* <IonCol>
              <IonButton routerLink="/signup" color="light" expand="block">Signup</IonButton>
            </IonCol> */}
          </IonRow>
        </form>
      </IonContent>

      <IonFooter>
        <IonCard>
          <IonItem>
            <IonIcon color="warning" icon={warningOutline}></IonIcon>
            <IonLabel color="warning">&nbsp;Uji coba ODI Versi 2.2</IonLabel>
          </IonItem>
          <IonCardContent>
            <IonText>
              Jika anda menemui kendala, silahkan laporkan di group ini:
              https://www.facebook.com/groups/odipertamina
            </IonText>
          </IonCardContent>
        </IonCard>
      </IonFooter>
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    isLoading: state.user.loading,
  }),
  mapDispatchToProps: {
    setUsername,
    setIsAuthenticated,
    setUserData,
    setLoading,
    loadDataLogin,
    logInUser,
  },
  component: Login,
});
