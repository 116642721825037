import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { connect } from "../data/connect";
import { useTranslation } from "react-i18next";
import "./Help.css";
import { User } from "../models/User";
import moment from "moment";
import { useHistory } from "react-router";

interface StateProps {
  user: User;
}

type HelpPageProps = StateProps;

const Help: React.FC<HelpPageProps> = ({ user }) => {
  const [t, i18n] = useTranslation("common");
  const history = useHistory();
  const regex = /<[^>]+>/gm;

  // Define a replacement mapping for each <...> pattern

  console.log(user);

  const feedbackRouteLink = () => {
    if (!!user && !!user.feedback_apps) {
      const replacementMapping = {
        "<no_spbu>": `spbu=${user.user_name};tanggal=${moment().format(
          "YYYY-MM-DD HH:mm"
        )};no_hp=${user.mobile}`,
        "<no_hp>": user.mobile,
        // Add more mappings as needed
      };

      const modifiedUrl = user.feedback_apps.replace(regex, (match: any) => {
        //@ts-ignore
        return replacementMapping[match] || match;
      });
      window.location.href = modifiedUrl;
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="help-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
        </div>
        <div className="ion-padding">
          <h4>Online Delivery Info (ODI)</h4>
          <p>
            This application is a small part of a fully integrated Automation
            System of Pertamina and its Subsidiaries
          </p>
          <p>
            Contact developers for suggestion <br />
            Email :{" "}
            <a
              href="mailto:support@smart-leaders.net?subject=ODI%20suggestion&amp;body=Suggestion%20for%20Online%20Delivery%20Info%20(ODI)"
              data-nsfw-filter-status="swf"
            >
              support@smart-leaders.net
            </a>
            <br />
            Web : <a href="http://smart-leaders.net">smart-leaders.net</a>
            <br />
            <br />
            Please provide your suggestions and complaints regarding the ODI
            IFMS application at the following link:
            <br />
            <a onClick={feedbackRouteLink}>Form Feedback</a>
            <br />
            <br />
            "Want it faster?" Use the Android app version on your mobile device.
            <br />
            Tutorial :{" "}
            <a href="https://www.youtube.com/watch?v=Xd3vGif-PMA" download>
              here
            </a>
            <br />
            Download :{" "}
            <a href="http://182.23.86.216/download/apk/odi_v2.0.apk" download>
              here
            </a>
            <br />
            Demo : <a href="https://beta.odi.smart-leaders.net/">here</a>
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connect<StateProps>({
  mapStateToProps: (state) => ({
    user: state.user.userData,
  }),
  component: React.memo(Help),
});
