import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { aperture, closeOutline, flag } from "ionicons/icons";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "../../data/connect";
import {
  refreshJustifyData,
  refreshTransportLossAll,
  setResInfoAfterSend,
  updateTransportLossOfflineData,
} from "../../data/data/data.actions";
import { sendTransportLossFormData } from "../../data/sync";
import { Justify } from "../../models/Transportloss";
import "./TransportLossJustify.scss";

interface OwnProps {
  onDismissModal: () => void;
  calcData: any;
}

interface StateProps {
  justifyOptions: Justify[];
  isLoadingJustify: boolean;
  isJusitfyLoaded: true | null;
}

interface DispatchProps {
  setResInfoAfterSend: typeof setResInfoAfterSend;
  updateTransportLossOfflineData: typeof updateTransportLossOfflineData;
  refreshTransportLossAll: typeof refreshTransportLossAll;
  refreshJustifyData: typeof refreshJustifyData;
}

const TransportLossJustify: React.FC<OwnProps & StateProps & DispatchProps> = ({
  refreshTransportLossAll,
  refreshJustifyData,
  updateTransportLossOfflineData,
  setResInfoAfterSend,
  onDismissModal,
  isJusitfyLoaded,
  isLoadingJustify,
  justifyOptions,
  calcData,
}) => {
  const [isSending, setIsSending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const [check, setCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmitData = async (data: any) => {
    if (data.users === "" || data.users === undefined) {
      setErrorMessage("'Petugas' harus di isi.");
      return;
    }
    if (data.password === "" || data.password === undefined) {
      setErrorMessage("'Password' harus di isi.");
      return;
    }
    if (!check) {
      setErrorMessage("must check box");
      return;
    }
    if (data.password !== calcData.password) {
      setErrorMessage("Password/PIN AMT 1 tidak Sesuai");
      return;
    }

    if (data.justify_reason === "" || data.justify_reason === undefined) {
      data = { ...calcData, ...data, ...{ is_justified: false } };
    } else {
      data = { ...calcData, ...data, ...{ is_justified: true } };
    }

    //console.log(JSON.stringify(data, null, 2));
    setIsSending(true);
    const { msg, responseStatus } = await sendTransportLossFormData(data);
    await updateTransportLossOfflineData(data);
    await refreshTransportLossAll();
    setIsSending(false);

    setResInfoAfterSend(msg, responseStatus);
    onDismissModal();
  };

  // useEffect(() => {
  //   if (!isJusitfyLoaded && !isLoadingJustify) {
  //     refreshJustifyData(false);
  //   }
  // }, [justifyOptions]);

  console.log(calcData);

  return (
    <IonPage id="transport-loss-justify-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Form Transport Loss</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismissModal}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <hr />
        <br />
        <form onSubmit={handleSubmit(onSubmitData)}>
          <IonText>
            <h5>
              <strong>VERIFIKASI</strong>
            </h5>
            <h6>
              Apahkah anda sudah yakin nilai yang diinput sudah sesuai dengan
              sebenarnya ?
            </h6>
          </IonText>
          {calcData.is_justified === true && (
            <div className="ion-padding-top">
              <IonLabel>
                <h5>
                  <strong>Justify Reason</strong>
                </h5>
              </IonLabel>
              {isLoadingJustify && (
                <div className="spinner-container-justify">
                  <IonSpinner name="bubbles" color="primary" />
                </div>
              )}
              {!isLoadingJustify && (
                <IonSelect
                  interface="popover"
                  {...register("justify_reason", { required: true })}
                >
                  {justifyOptions.map((option, index) => (
                    <IonSelectOption
                      key={`justify-option-${index}`}
                      value={option.id}
                    >
                      {option.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
            </div>
          )}
          <div className="ion-padding-top">
            <IonLabel>
              <h5>
                <strong>Petugas</strong>
              </h5>
            </IonLabel>
            <IonInput {...register("users")}></IonInput>
          </div>
          <div className="ion-padding-top">
            <IonLabel>
              <h5>
                <strong>Password (PIN_Supir)</strong>
              </h5>
            </IonLabel>
            <IonInput type="password" {...register("password")}></IonInput>
          </div>
          <hr />
          <div className="ion-padding-top">
            <IonRow>
              <IonCol size="1">
                <IonCheckbox
                  onIonChange={(e) => setCheck(e.detail.checked)}
                ></IonCheckbox>
              </IonCol>
              <IonCol size="11">
                <IonText>
                  saya telah membaca dan menyatakan bahwa data tersebut adalah
                  benar.
                </IonText>
              </IonCol>
            </IonRow>
          </div>
          <div className="ion-padding-top">
            <IonButton type="submit" color="primary" expand="block">
              {isSending && <IonSpinner name="bubbles" color="light" />}
              Submit
            </IonButton>
          </div>
        </form>
        <IonToast
          cssClass="fail-toast"
          isOpen={errorMessage !== ""}
          message={errorMessage}
          duration={5000}
          onDidDismiss={() => setErrorMessage("")}
        />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    justifyOptions: state.data.justify,
    isLoadingJustify: state.data.dataLoadingJustify,
    isJusitfyLoaded: state.data._isJustifyLoaded,
  }),
  mapDispatchToProps: {
    setResInfoAfterSend,
    updateTransportLossOfflineData,
    refreshTransportLossAll,
    refreshJustifyData,
  },
  component: React.memo(TransportLossJustify),
});
