import React, { useEffect, useContext } from "react";
import { IonRouterContext } from "@ionic/react";

interface RedirectToLoginProps {
  setUserData: Function;
  setIsAuthenticated: Function;
  setOngoingDeliverysLoaded: Function;
  setPastDeliverysLoaded: Function;
  setOrderLoaded: Function;
  setFeedbackLoaded: Function;
  setTransportLossAllLoaded: Function;
  logoutUser: Function;
}

const RedirectToLogin: React.FC<RedirectToLoginProps> = ({
  setUserData,
  setIsAuthenticated,
  setOngoingDeliverysLoaded,
  setPastDeliverysLoaded,
  setOrderLoaded,
  setFeedbackLoaded,
  setTransportLossAllLoaded,
  logoutUser,
}) => {
  const ionRouterContext = useContext(IonRouterContext);
  useEffect(() => {
    // setIsLoggedIn(false);
    logoutUser();
    setIsAuthenticated(false);
    setUserData(undefined);
    setOngoingDeliverysLoaded(null);
    setPastDeliverysLoaded(null);
    setOrderLoaded(null);
    setFeedbackLoaded(null);
    setTransportLossAllLoaded(null);
    ionRouterContext.push("/login");
  }, [setUserData, ionRouterContext]);
  return null;
};

export default RedirectToLogin;
