import React, { useState, useRef, useEffect } from "react";

import {
  IonListHeader,
  IonList,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonHeader,
  getConfig,
  IonSpinner,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { search, syncSharp } from "ionicons/icons";

import * as selectors from "../data/selectors";
import { connect } from "../data/connect";
import { Transportloss } from "../models/Transportloss";
import TransportLossItem from "../components/ListItem/TransportLossItem";
import {
  setSearchText,
  refreshTransportLossAll2,
} from "../data/data/data.actions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TimeAgo from "../components/TimeAgo";
import SyncStatus from "../components/SyncStatus";

interface OwnProps {}

interface StateProps {
  transportLossList: Transportloss[];
  mode: "ios" | "md";
  transportLossAllMessage: string;
  transportLossAllStatus: string;
  lastUpdateTransportLossAll: string;
  isLoading: boolean;
  isLoadingTransportLossAll: boolean;
  isTransportLossAllLoaded: true | null;
}

interface DispatchProps {
  setSearchText: typeof setSearchText;
  refreshTransportLossAll2: typeof refreshTransportLossAll2;
}

type TransportLossPageProps = OwnProps & StateProps & DispatchProps;

const TransportLossPage: React.FC<TransportLossPageProps> = ({
  isLoading,
  lastUpdateTransportLossAll,
  transportLossAllMessage,
  transportLossAllStatus,
  isLoadingTransportLossAll,
  isTransportLossAllLoaded,
  transportLossList,
  mode,
  setSearchText,
  refreshTransportLossAll2,
}) => {
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);

  const [t, i18n] = useTranslation("common");

  const pageRef = useRef<HTMLElement>(null);

  const ios = mode === "ios";

  const onRefreshTransportLossAll2 = async (e?: React.FormEvent) => {
    e?.preventDefault();
    await refreshTransportLossAll2();
  };

  useEffect(() => {
    if (
      !isTransportLossAllLoaded &&
      !isLoadingTransportLossAll
      // && transportLossList.length === 0
    ) {
      onRefreshTransportLossAll2();
    }
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     refreshTransportLossAll2();
  //   }, 300000); // 60,000 milliseconds = 1 minute

  //   return () => {
  //     // Clean up the interval when the component unmounts
  //     clearInterval(intervalId);
  //   };
  // }, []);

  // console.log(transportLossList);

  return (
    <IonPage ref={pageRef}>
      <IonHeader translucent={true}>
        <IonToolbar>
          {!showSearchbar && (
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          )}
          {!ios && !showSearchbar && (
            <IonTitle>{t("menus.transport_loss")}</IonTitle>
          )}
          {showSearchbar && (
            <IonSearchbar
              showCancelButton="always"
              placeholder="Search"
              onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
            ></IonSearchbar>
          )}

          <IonButtons slot="end">
            {!ios && !showSearchbar && (
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        {/* {isLoadingTransportLossAll && (
          <div className="spin">
            <IonSpinner name="bubbles" color="primary" />
          </div>
        )}
        {!isLoadingTransportLossAll && ( */}
        <>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{t("menus.transport_loss")}</IonTitle>
            </IonToolbar>
            <IonToolbar>
              <IonSearchbar
                placeholder="Search"
                onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              ></IonSearchbar>
            </IonToolbar>
          </IonHeader>

          <div className="button-container">
            <IonButton
              onClick={onRefreshTransportLossAll2}
              expand="full"
              color="primary"
            >
              <IonIcon icon={syncSharp}></IonIcon>
            </IonButton>
          </div>

          <SyncStatus
            lastUpdate={lastUpdateTransportLossAll}
            message={transportLossAllMessage}
          />

          {/* {isLoadingTransportLossAll && ( */}
          <div
            className={`${
              isLoadingTransportLossAll ? "refreshing" : ""
            } spinner-overlay`}
          >
            <IonSpinner name="bubbles" color="primary" />
            {/* <span className="span-loading">{t("global.loading_download")}</span> */}
            <span className="span-loading">Downloading new data</span>
          </div>
          {/* )} */}

          <IonList
            className={`${
              isLoadingTransportLossAll ? "refreshing" : "resetting"
            } list-container`}
          >
            {transportLossList.length === 0 && (
              <IonListHeader>No Transport Loss Found</IonListHeader>
            )}

            {transportLossList.length !== 0 &&
              transportLossList.map((list: Transportloss, index: number) => (
                <TransportLossItem
                  transportLoss={list}
                  listType={list.State.toLowerCase()}
                  key={`transport-loss-all-${index}`}
                />
              ))}
          </IonList>
        </>
        {/* )} */}
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isLoading: state.data.dataLoading,
    isLoadingTransportLossAll: state.data.dataLoadingTransportLossAll,
    isTransportLossAllLoaded: state.data._transportLossAllLoaded,
    lastUpdateTransportLossAll: state.data.lastUpdateTransportLossAll,
    transportLossAllMessage: state.data.transportLossAllMessage,
    transportLossAllStatus: state.data.transportLossAllStatus,
    transportLossList: selectors.getSearchedTransportLossAll(state),
    mode: getConfig()!.get("mode"),
  }),
  mapDispatchToProps: {
    setSearchText,
    refreshTransportLossAll2,
  },
  component: React.memo(TransportLossPage),
});
