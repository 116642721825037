import { FetchObject } from "../models/FetchObject";

const load_dummy = process.env.REACT_APP_GET_DUMMY_DATA === "1";

const baseUrl = load_dummy ? "" : process.env.REACT_APP_WEBSERVER_HOST;
// || "http://192.140.225.118:4500/odiapk2";
// || "https://odi.smart-leaders.net/odiapk2";
const fileUrl = "./assets/data";
const json = ".json";

const fetchRoute = async (
  url: string,
  options?: object
): Promise<FetchObject> => {
  if (!load_dummy) {
    // return await (await fetch(baseUrl + url, options)).json();
    try {
      const response = await (await fetch(baseUrl + url, options)).json();
      console.log(
        response.status === "E" &&
          (response.desc_msg !== "Data not found" ||
            response.desc_msg !== "Invalid Username or Password")
      );
      if (
        response.status === "E" &&
        !(
          response.desc_msg === "Data not found" ||
          response.desc_msg === "Invalid Username or Password"
        )
      ) {
        response.desc_msg = "Error from server";
      }
      return response;
    } catch {
      console.log("ERROR");
      return {
        data: [],
        desc_msg: "Server can not be connected",
        status: "E",
      };
    }
  }

  // @ts-ignore
  const survey_id = options?.body?.getAll("survey_id")[0];
  // @ts-ignore
  const shipment = options?.body?.getAll("menu")[0];
  // @ts-ignore
  const feedbackOption = options?.body?.getAll("code")[0];

  if (!!survey_id) {
    return (
      // @ts-ignore
      (await fetch(fileUrl + url + "_" + survey_id + json)).json()
    );
  } else if (!!shipment) {
    // return (await fetch(fileUrl + url + "_" + shipment + json)).json();
    const response = await (
      await fetch(fileUrl + url + "_" + shipment + json)
    ).json();
    console.log(response.desc_msg !== "Data Not Found");
    if (
      response.status === "E" &&
      response.desc_msg === "Server can not be connected"
    ) {
      response.desc_msg = "Server can not be connected";
    } else if (
      response.status === "E" &&
      (response.desc_msg !== "Data not found" ||
        response.desc_msg !== "Invalid Username or Password")
    ) {
      response.desc_msg = "Error from server";
    }

    return response;
  } else if (!!feedbackOption) {
    const response = await (
      await fetch(fileUrl + "/feedback_options/" + feedbackOption + json)
    ).json();
    console.log(response.desc_msg !== "Data Not Found");
    if (
      response.status === "E" &&
      response.desc_msg === "Server can not be connected"
    ) {
      response.desc_msg = "Server can not be connected";
    } else if (
      response.status === "E" &&
      (response.desc_msg !== "Data not found" ||
        response.desc_msg !== "Invalid Username or Password")
    ) {
      response.desc_msg = "Error from server";
    }

    return response;
  } else {
    return (
      // @ts-ignore
      (await fetch(fileUrl + url + json)).json()
    );
  }
};

export default fetchRoute;
