import React, { useState, useRef, useEffect } from "react";

import {
  IonListHeader,
  IonList,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonHeader,
  getConfig,
  IonSpinner,
  IonBadge,
  IonItem,
  IonLabel,
} from "@ionic/react";
import {
  list,
  notificationsCircleOutline,
  search,
  syncSharp,
} from "ionicons/icons";
import "./MainPage.css";
import * as selectors from "../data/selectors";
import { connect } from "../data/connect";
import DeliveryItem from "../components/ListItem/DeliveryItem";
import { Delivery } from "../models/Delivery";
import {
  setSearchText,
  refreshOngoingDeliverysData,
  refreshPastDeliverysData,
  refreshDeliverysData,
} from "../data/data/data.actions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TimeAgo from "../components/TimeAgo";
import SyncStatus from "../components/SyncStatus";
import "./Delivery.scss";

interface OwnProps {}

interface StateProps {
  pastDeliveryList: Delivery[];
  ongoingDeliveryList: Delivery[];
  lastUpdateOngoingDelivery: string;
  lastUpdatePastDelivery: string;
  ongoingDeliveryMessage: string;
  ongoingDeliveryStatus: any;
  pastDeliveryMessage: string;
  pastDeliveryStatus: any;
  mode: "ios" | "md";
  isLoadingOngoingDeliverys: boolean;
  isLoadingPastDeliverys: boolean;
  isOngoingDeliverysLoaded: true | null;
  isPastDeliverysLoaded: true | null;
  willSendCount: number;
}

interface DispatchProps {
  setSearchText: typeof setSearchText;
  refreshDeliverysData: typeof refreshDeliverysData;
  refreshOngoingDeliverysData: typeof refreshOngoingDeliverysData;
  refreshPastDeliverysData: typeof refreshPastDeliverysData;
}

type DeliveryPageProps = OwnProps & StateProps & DispatchProps;

const DeliveryPage: React.FC<DeliveryPageProps> = ({
  willSendCount,
  ongoingDeliveryStatus,
  pastDeliveryStatus,
  lastUpdateOngoingDelivery,
  lastUpdatePastDelivery,
  ongoingDeliveryMessage,
  pastDeliveryMessage,
  isOngoingDeliverysLoaded,
  isPastDeliverysLoaded,
  isLoadingOngoingDeliverys,
  isLoadingPastDeliverys,
  ongoingDeliveryList,
  pastDeliveryList,
  mode,
  setSearchText,
  refreshDeliverysData,
  refreshOngoingDeliverysData,
  refreshPastDeliverysData,
}) => {
  const [segment, setSegment] = useState<"ongoing" | "past">("ongoing");
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [listClass, setListClass] = useState("");
  const pageRef = useRef<HTMLElement>(null);
  const [t, i18n] = useTranslation("common");

  const ios = mode === "ios";

  const onRefreshOngoingDeliverysData = async (e: React.FormEvent) => {
    e.preventDefault();
    await refreshOngoingDeliverysData(false);
    // setListClass("resetting"); // Apply a CSS class to trigger the animation
  };

  const onRefreshPastDeliverysData = async (e: React.FormEvent) => {
    e.preventDefault();
    await refreshPastDeliverysData(false);
  };

  const onRefereshDeliverysData = async (e?: React.FormEvent) => {
    e?.preventDefault();
    await refreshDeliverysData(false);
  };

  useEffect(() => {
    if (
      !isOngoingDeliverysLoaded &&
      !isLoadingOngoingDeliverys &&
      segment === "ongoing"
    ) {
      refreshOngoingDeliverysData(false);
    }
    if (
      !isPastDeliverysLoaded &&
      !isLoadingPastDeliverys &&
      segment === "past"
    ) {
      refreshPastDeliverysData(false);
    }
  }, [segment]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     refreshOngoingDeliverysData(false);
  //   }, 300000); // 60,000 milliseconds = 1 minute

  //   return () => {
  //     // Clean up the interval when the component unmounts
  //     clearInterval(intervalId);
  //   };
  // }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     refreshPastDeliverysData(false);
  //   }, 300000); // 60,000 milliseconds = 1 minute

  //   return () => {
  //     // Clean up the interval when the component unmounts
  //     clearInterval(intervalId);
  //   };
  // }, []);

  return (
    <IonPage ref={pageRef} id="delivery-list">
      <IonHeader translucent={true}>
        <IonToolbar>
          {!showSearchbar && (
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          )}
          {ios && (
            <IonSegment
              value={segment}
              onIonChange={(e) => setSegment(e.detail.value as any)}
            >
              <IonSegmentButton
                className="ongoing-segment-button"
                value="ongoing"
              >
                {t("pages_delivery.category_ongoing")}
              </IonSegmentButton>
              <IonSegmentButton className="past-segment-button" value="past">
                {t("pages_delivery.category_past")}
              </IonSegmentButton>
            </IonSegment>
          )}
          {!ios && !showSearchbar && (
            <IonTitle>{t("pages_delivery.title")}</IonTitle>
          )}
          {showSearchbar && (
            <IonSearchbar
              showCancelButton="always"
              placeholder="Search"
              onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
            ></IonSearchbar>
          )}

          <IonButtons slot="end">
            {!ios && !showSearchbar && (
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
            )}
            {willSendCount > 0 && (
              <IonButton id="sync-noti">
                <IonIcon icon={notificationsCircleOutline}></IonIcon>
                <IonBadge id="sync-badge" color="primary">
                  {willSendCount}
                </IonBadge>
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>

        {!ios && (
          <IonToolbar>
            <IonSegment
              value={segment}
              onIonChange={(e) => setSegment(e.detail.value as any)}
            >
              <IonSegmentButton
                className="ongoing-segment-button"
                value="ongoing"
              >
                OnGoing
              </IonSegmentButton>
              <IonSegmentButton className="past-segment-button" value="past">
                Past
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        )}
      </IonHeader>

      <IonContent fullscreen={true}>
        <>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{t("pages_delivery.title")}</IonTitle>
            </IonToolbar>
            <IonToolbar>
              <IonSearchbar
                placeholder="Search"
                onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              ></IonSearchbar>
            </IonToolbar>
          </IonHeader>

          {/* {segment === "ongoing" && isLoadingOngoingDeliverys && (
            <div className="spin">
              <IonSpinner name="bubbles" color="primary" />
            </div>
          )} */}

          {segment === "ongoing" && (
            <>
              <div className="button-container">
                <IonButton
                  onClick={onRefreshOngoingDeliverysData}
                  expand="full"
                  color="primary"
                >
                  <IonIcon icon={syncSharp}></IonIcon>
                </IonButton>
              </div>

              <SyncStatus
                lastUpdate={lastUpdateOngoingDelivery}
                message={ongoingDeliveryMessage}
              />

              {/* {isLoadingOngoingDeliverys && ( */}
              <div
                className={`${
                  isLoadingOngoingDeliverys ? "refreshing" : ""
                } spinner-overlay`}
              >
                <IonSpinner name="bubbles" color="primary" />

                {/* <span className="span-loading">
                  {t("global.loading_download")}
                </span> */}
                <span className="span-loading">Downloading new data</span>
              </div>
              {/* )} */}

              <IonList
                className={`${
                  isLoadingOngoingDeliverys ? "refreshing" : ""
                } list-container`}
              >
                {ongoingDeliveryList.length === 0 && (
                  <IonListHeader>No Ongoing Delivery Found</IonListHeader>
                )}
                {ongoingDeliveryList.length !== 0 &&
                  ongoingDeliveryList.map((list: Delivery, index: number) => (
                    <DeliveryItem
                      delivery={list}
                      listType="ongoing"
                      key={`delivery-ongoing-${index}`}
                    />
                  ))}
              </IonList>
            </>
          )}

          {/* {segment === "past" && isLoadingPastDeliverys && (
            <div className="spin">
              <IonSpinner name="bubbles" color="primary" />
            </div>
          )} */}

          {segment === "past" && (
            <>
              <div className="button-container">
                <IonButton
                  onClick={onRefreshPastDeliverysData}
                  expand="full"
                  color="success"
                >
                  <IonIcon icon={syncSharp}></IonIcon>
                </IonButton>
              </div>

              <SyncStatus
                lastUpdate={lastUpdatePastDelivery}
                message={pastDeliveryMessage}
              />

              {/* {isLoadingPastDeliverys && ( */}
              <div
                className={`${
                  isLoadingPastDeliverys ? "refreshing" : ""
                } spinner-overlay`}
              >
                <IonSpinner name="bubbles" color="primary" />

                {/* <span className="span-loading">
                  {t("global.loading_download")}
                </span> */}
                <span className="span-loading">Downloading new data</span>
              </div>
              {/* )} */}
              <IonList
                className={`${
                  isLoadingPastDeliverys ? "refreshing" : ""
                } list-container`}
              >
                {pastDeliveryList.length === 0 && (
                  <IonListHeader>No Past Delivery Found</IonListHeader>
                )}

                {pastDeliveryList.length !== 0 &&
                  pastDeliveryList.map((list: Delivery, index: number) => (
                    <DeliveryItem
                      delivery={list}
                      listType="past"
                      key={`delivery-past-${index}`}
                    />
                  ))}
              </IonList>
            </>
          )}
        </>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isLoadingOngoingDeliverys: state.data.dataLoadingOngoingDeliverys,
    isLoadingPastDeliverys: state.data.dataLoadingPastDeliverys,
    isOngoingDeliverysLoaded: state.data._ongoingDeliverysLoaded,
    isPastDeliverysLoaded: state.data._pastDeliverysLoaded,
    lastUpdateOngoingDelivery: state.data.lastUpdateOngoingDelivery,
    lastUpdatePastDelivery: state.data.lastUpdatePastDelivery,
    ongoingDeliveryStatus: state.data.ongoingDeliverysStatus,
    ongoingDeliveryMessage: state.data.ongoingDeliverysMessage,
    pastDeliveryMessage: state.data.pastDeliverysMessage,
    pastDeliveryStatus: state.data.pastDeliverysStatus,
    ongoingDeliveryList: selectors.getSearchedOngoingDeliverys(state),
    pastDeliveryList: selectors.getSearchedPastDeliverys(state),
    mode: getConfig()!.get("mode"),
    willSendCount: state.data.willSendCount,
  }),
  mapDispatchToProps: {
    setSearchText,
    refreshOngoingDeliverysData,
    refreshPastDeliverysData,
    refreshDeliverysData,
  },
  component: React.memo(DeliveryPage),
});
