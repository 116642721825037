import { CheckList } from "../models/CheckList";
import { Delivery } from "../models/Delivery";
import { Driver } from "../models/Driver";
import {
  Feedback,
  FeedbackOption,
  FeedbackOptionAll,
  FeedbackOptionChild,
  Feedback_Data,
  Pengirim,
  Respon,
  Shipment,
} from "../models/Feedback";
import { Order } from "../models/Order";
import {
  Justify,
  LossFormData,
  LossFormDataOffline,
  Tank,
  Transportloss,
} from "../models/Transportloss";
import { User } from "../models/User";
import { Appsx } from "../models/Appsx";
import { Comp, Vehicle } from "../models/Vehicle";
import {
  FileTransfer,
  FileTransferObject,
} from "@awesome-cordova-plugins/file-transfer";
import { File, FileEntry } from "@awesome-cordova-plugins/file";
import fetchRoute from "./fetchRoute";

// const baseUrl = 'http://192.168.0.233:4000/odi';
const baseUrl = "https://odi.smart-leaders.net/odiapk2";
// const baseUrl = 'http://192.168.0.233:4000/odi'
/*const baseUrl = 'http://147.139.163.223:4000/odi';*/
/*const baseUrl = 'http://182.23.86.213:4000/odi';*/

let commonFormData = new FormData();
let spbu: string;

export const fileDownload = async (url: string) => {
  const fileTransfer: FileTransferObject = FileTransfer.create();
  const filename = url.match(/=[a-zA-Z._\s0-9]+.pdf/g)![0].substring(1);
  let entry: FileEntry | null = null;

  try {
    entry = await fileTransfer.download(
      url,
      File.externalRootDirectory + "/Download/" + filename
    );
  } catch (err) {
    alert("Download failed" + JSON.stringify(err));
  }

  if (!entry) {
    return "";
  } else {
    return entry.toURL();
  }
};

/*
export const getApps = async (formData : FormData) => {
  const response = await Promise.all([
    fetch(`${baseUrl}/getapps`, {
      method: "post",
      body: formData
    }), 
  ]);
  const responseData = await response[0].json();
  const status = responseData.status as string;
  if (status === "E") {
    return;
  }
  else if (status === "S") {
    let apps = responseData.data[0] as Appsx;
    return apps;
  }
}
*/

export const authenticateFunc = async (formData: FormData) => {
  const response = await Promise.all([
    // fetch(`${baseUrl}/authenticate`, {
    fetchRoute(`/authenticate`, {
      method: "post",
      body: formData,
    }),
  ]);
  // const responseData = response[0].json();
  const responseData = response[0];
  const status = responseData.status as string;
  if (status === "E") {
    return responseData;
  } else if (status === "S") {
    let user = responseData.data[0] as User;
    putUserInfoInFormData(user);
    return responseData;
  }
};

export const putUserInfoInFormData = (user: User) => {
  commonFormData.delete("spbu");
  commonFormData.delete("company_id");
  commonFormData.append("spbu", user.user_name);
  commonFormData.append("company_id", user.company_id.toString());
  spbu = user.user_name;
};

export const getApiOngoingDelivery = async () => {
  commonFormData.append("menu", "ongoing_deliveries");

  const response_ongoing = await Promise.all([
    // fetch(`${baseUrl}/shipment`, {
    fetchRoute(`/shipment`, {
      method: "post",
      body: commonFormData,
    }),
  ]);

  // const ongoingData = await response_ongoing[0].json();
  const ongoingData = response_ongoing[0];

  const ongoingDeliverys = ongoingData.data as Delivery[];
  const ongoingDataResponse = {
    ongoingDeliveryMessage: ongoingData.desc_msg,
    ongoingDeliveryStatus: ongoingData.status,
  };

  commonFormData.delete("menu");

  const lastUpdateOngoingDelivery = new Date().toISOString();

  const deliverysData = {
    ongoingDeliverys,
    lastUpdateOngoingDelivery: lastUpdateOngoingDelivery,
  };

  return {
    deliverysData,
    ongoingDataResponse,
  };
};

export const getApiPastDelivery = async () => {
  commonFormData.append("menu", "past_deliveries");

  const response_past = await Promise.all([
    // fetch(`${baseUrl}/shipment`, {
    fetchRoute(`/shipment`, {
      method: "post",
      body: commonFormData,
    }),
  ]);

  // const ongoingData = await response_ongoing[0].json();
  const pastData = response_past[0];

  const pastDeliverys = pastData.data as Delivery[];
  const pastDataResponse = {
    pastDeliveryMessage: pastData.desc_msg,
    pastDeliveryStatus: pastData.status,
  };

  commonFormData.delete("menu");

  const lastUpdatePastDelivery = new Date().toISOString();

  const deliverysData = {
    // delivery: {
    pastDeliverys,
    lastUpdatePastDelivery: lastUpdatePastDelivery,
    // },
  };

  return {
    deliverysData,
    pastDataResponse,
  };
};

export const getApiDelivery = async () => {
  commonFormData.append("menu", "ongoing_deliveries");

  const response_ongoing = await Promise.all([
    // fetch(`${baseUrl}/shipment`, {
    fetchRoute(`/shipment`, {
      method: "post",
      body: commonFormData,
    }),
  ]);

  // const ongoingData = await response_ongoing[0].json();
  const ongoingData = response_ongoing[0];

  const ongoingDeliverys = ongoingData.data as Delivery[];
  const ongoingDataResponse = {
    ongoingDeliveryMessage: ongoingData.desc_msg,
    ongoingDeliveryStatus: ongoingData.status,
  };

  commonFormData.delete("menu");
  commonFormData.append("menu", "past_deliveries");

  const response_past = await Promise.all([
    // fetch(`${baseUrl}/shipment`, {
    fetchRoute(`/shipment`, {
      method: "post",
      body: commonFormData,
    }),
  ]);
  // const pastData = await response_past[0].json();
  const pastData = response_past[0];
  const pastDeliverys = pastData.data as Delivery[];
  const pastDataResponse = {
    pastDeliveryMessage: pastData.desc_msg,
    pastDeliveryStatus: pastData.status,
  };

  commonFormData.delete("menu");

  const shipIds = [
    ...ongoingDeliverys.map((d) => {
      return d.shipment_id;
    }) /*, 
    ...pastDeliverys.map(d => { return d.shipment_id; })*/,
  ];

  const shipIdsPast = [
    ...pastDeliverys.map((d) => {
      return d.shipment_id;
    }),
  ];

  const driverAssistantIDs = [
    ...ongoingDeliverys.map((d) => {
      return [d.driver_id, d.driver_assistant_id];
    }) /*, 
    ...pastDeliverys.map(d => { return [d.driver_id, d.driver_assistant_id]; }) ,*/,
  ];

  const driverAssistantIDsPast = [
    ...pastDeliverys.map((d) => {
      return [d.driver_id, d.driver_assistant_id];
    }),
  ];

  const vehicleIDs = [
    ...ongoingDeliverys.map((d) => {
      return d.vehicle_id;
    }) /*, 
    ...pastDeliverys.map(d => { return d.vehicle_id; })*/,
  ];

  const vehicleIDsPast = [
    ...pastDeliverys.map((d) => {
      return d.vehicle_id;
    }),
  ];

  const spbuIds = [
    ...ongoingDeliverys.map((d) => {
      return d.spbu;
    }),
    ...pastDeliverys.map((d) => {
      return d.spbu;
    }),
  ];

  const lastUpdateDelivery = new Date().toISOString();

  const deliverysData = {
    delivery: {
      ongoingDeliverys,
      pastDeliverys,
      lastUpdateDelivery: lastUpdateDelivery,
    },
    shipIds: shipIds,
    shipIdsPast: shipIdsPast,
    driverAssistantIDs: driverAssistantIDs,
    driverAssistantIDsPast: driverAssistantIDsPast,
    vehicleIDs: vehicleIDs,
    vehicleIDsPast: vehicleIDsPast,
    spbuIds: spbuIds,
  };

  return {
    deliverysData,
    ongoingDataResponse,
    pastDataResponse,
  };
};

export const getApiOrders = async () => {
  const response = await Promise.all([
    // fetch(`${baseUrl}/order`, {
    fetchRoute(`/order`, {
      method: "post",
      body: commonFormData,
    }),
  ]);

  // const responseData = await response[0].json();
  const responseData = response[0];

  const orders = responseData.data as Order[];
  // const orders = {orders: responseData.data as Order[]};

  const orderResponse = {
    orderMessage: responseData.desc_msg,
    orderStatus: responseData.status,
  };

  const lastUpdateOrders = new Date().toISOString();

  return {
    orders,
    orderResponse,
    lastUpdateOrders,
  };
};

export const getApiFeedbacks = async () => {
  const response = await Promise.all([
    // fetch(`${baseUrl}/feedback`, {
    fetchRoute(`/feedback`, {
      method: "post",
      body: commonFormData,
    }),
  ]);
  // const responseData = await response[0].json();
  const responseData = response[0];
  const feedbacks = responseData.data as Feedback[];

  const feedbackResponse = {
    feedbackMessage: responseData.desc_msg,
    feedbackStatus: responseData.status,
  };

  const lastUpdateFeedbacks = new Date().toISOString();

  return {
    feedbacks,
    feedbackResponse,
    lastUpdateFeedbacks,
  };
};

export const getApiTransportLossAll = async () => {
  const response = await Promise.all([
    // fetch(`${baseUrl}/transportloss_all`, {
    fetchRoute(`/transportloss_all`, {
      method: "post",
      body: commonFormData,
    }),
  ]);
  // const responseData = await response[0].json();
  const responseData = response[0];

  const transLossAll = responseData.data as Transportloss[];

  const transportLossAllResponse = {
    transportLossAllMessage: responseData.desc_msg,
    transportLossAllStatus: responseData.status,
  };

  const lastUpdateTransportLossAll = new Date().toISOString();

  return {
    transLossAll,
    transportLossAllResponse,
    lastUpdateTransportLossAll,
  };
};

export const getApiDriverDetails = async (driverIDs: Array<any>) => {
  const formData = new FormData();
  formData.append("driver_id", driverIDs.join(","));
  const response = await Promise.all([
    // fetch(`${baseUrl}/driver_detail`, {
    fetchRoute(`/driver_detail`, {
      method: "post",
      body: formData,
    }),
  ]);
  // const responseData = await response[0].json();
  const responseData = response[0];
  console.log(responseData);
  const drivers = responseData.data as Driver[];

  return {
    drivers,
  };
};

export const getApiVehicleDatails = async (vehicleIDs: Array<any>) => {
  const formData = new FormData();
  formData.append("vehicle_id", vehicleIDs.join(","));

  const response = await Promise.all([
    // fetch(`${baseUrl}/vehicle_detail`, {
    fetchRoute(`/vehicle_detail`, {
      method: "post",
      body: formData,
    }),
  ]);
  // const responseData = await response[0].json();
  const responseData = response[0];
  const vehicleDetails = responseData.data as [];

  const vehicles = vehicleDetails.map((vehicleDetail) => {
    const comps = [] as Comp[];
    [1, 2, 3, 4, 5, 6].forEach((i) => {
      const comp = {
        empty_space: vehicleDetail[`empty_space_comp${i}`],
        sensitivity: vehicleDetail[`sensitivity_comp${i}`],
        t2: vehicleDetail[`t2_comp${i}`],
      };
      comps.push(comp);
    });

    const vehicle = {
      attachment: vehicleDetail["attachment"],
      capacity: vehicleDetail["capacity"],
      comp: comps,
      id: vehicleDetail["id"],
      name: vehicleDetail["name"],
      re_calibration_time: vehicleDetail["re_calibration_time"],
      rental_type: vehicleDetail["rental_type"],
    };

    return vehicle;
  });

  return {
    vehicles,
  };
};

export const getApiCheckLists = async () => {
  const formData = new FormData();
  formData.append("survey_id", "7");
  const response = await Promise.all([
    // fetch(`${baseUrl}/get_survey`, {
    fetchRoute(`/get_survey`, {
      method: "post",
      body: formData,
    }),
  ]);
  // const responseData = await response[0].json();
  const responseData = response[0];
  const checkLists = responseData.data as CheckList[];

  const checkListsResponse = {
    checkListsMessage: responseData.desc_msg,
    checkListsStatus: responseData.status,
  };

  const lastUpdateCheckLists = new Date().toISOString();

  return {
    checkLists,
    checkListsResponse,
    lastUpdateCheckLists,
  };
};

export const getApiTanks = async () => {
  const formData = new FormData();
  formData.append("site_id", spbu);

  const response = await Promise.all([
    // fetch(`${baseUrl}/slc_tank_spbu`, {
    fetchRoute(`/slc_tank_spbu`, {
      method: "post",
      body: formData,
    }),
  ]);
  // const responseData = await response[0].json();
  const responseData = response[0];
  const tanks = responseData.data as Tank[];

  const tanksResponse = {
    tanksMessage: responseData.desc_msg,
    tanksStatus: responseData.status,
  };

  const lastUpdateTanks = new Date().toISOString();

  return {
    tanks,
    tanksResponse,
    lastUpdateTanks,
  };
};

export const getApiJustify = async () => {
  const response = await Promise.all([
    // fetch(`${baseUrl}/slc_justify`, {
    fetchRoute(`/slc_justify`, {
      method: "post",
    }),
  ]);
  // const responseData = await response[0].json();
  const responseData = response[0];
  const justify = responseData.data as Justify[];

  const justifyResponse = {
    justifyMessage: responseData.desc_msg,
    justifyStatus: responseData.status,
  };

  const lastUpdateJustify = new Date().toISOString();

  return {
    justify,
    justifyResponse,
    lastUpdateJustify,
  };
};

export const getApiLossFormData = async () => {
  const response = await Promise.all([
    // fetch(`${baseUrl}/transportloss`, {
    fetchRoute(`/transportloss`, {
      method: "post",
      body: new FormData(),
    }),
  ]);
  // const responseData = await response[0].json();
  const responseData = response[0];
  const lossFormData = responseData.data as LossFormData[];

  return lossFormData[0];
};

export const getApiLossFormOffineData = async (shipIds: Array<any>) => {
  const formData = new FormData();
  formData.append("shipids", shipIds.join(","));
  formData.append("no_spbu", spbu);
  const response = await Promise.all([
    // fetch(`${baseUrl}/transportloss_ofline`, {
    fetchRoute(`/transportloss_ofline`, {
      method: "post",
      body: formData,
    }),
  ]);
  // const responseData = await response[0].json();
  const responseData = response[0];
  // console.log(responseData);
  const transFormOfflineDatas = responseData.data as LossFormDataOffline[];

  return {
    transFormOfflineDatas,
  };
};

export const getFeedbackOptions = async (
  id: string | undefined,
  code: string | undefined
) => {
  const formData = new FormData();
  formData.append("id", id ?? "");
  formData.append("code", code ?? "");

  const response = await Promise.all([
    // fetch(`${baseUrl}/slc_feedback`, {
    fetchRoute(`/slc_feedback`, {
      method: "post",
      body: formData,
    }),
  ]);

  // const responseData = await response[0].json();
  const responseData = response[0];
  const options = responseData.data as FeedbackOption[];
  return options;
};

export const getApiFeedbackOfflineOptions = async () => {
  const codes = ["A", "B", "C", "D", "E"];
  const feedbackOptions = [] as FeedbackOptionAll[];

  for (let i = 0; i < codes.length; ++i) {
    const formData = new FormData();
    formData.append("id", "");
    formData.append("code", codes[i]);
    formData.append("all_data", "True");

    const response = await Promise.all([
      // fetch(`${baseUrl}/slc_feedback`, {
      fetchRoute(`/slc_feedback`, {
        method: "post",
        body: formData,
      }),
    ]);

    // const responseData = await response[0].json();
    const responseData = response[0];
    const options = responseData.data as FeedbackOptionChild[];
    feedbackOptions.push({ code: codes[i], data: options });
  }

  return {
    feedbackOptions,
  };
};

export const sendApiFeedback = async (data: object) => {
  const formData = new FormData();
  formData.append("nospbu", spbu);
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const response = await Promise.all([
    // fetch(`${baseUrl}/send_feedback`, {
    fetchRoute(`/send_feedback`, {
      method: "post",
      body: formData,
    }),
  ]);

  // const responseData = await response[0].json();
  const responseData = response[0];
  const status = responseData.status as string;
  const desc_msg = responseData.desc_msg as string;

  return {
    status: status,
    message: desc_msg,
  };
};

export const sendApiFeedbackStatus = async (data: object) => {
  const formData = new FormData();
  formData.append("nospbu", spbu);
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const response = await Promise.all([
    // fetch(`${baseUrl}/send_feedback_status`, {
    fetchRoute(`/send_feedback_status`, {
      method: "post",
      body: formData,
    }),
  ]);

  // const responseData = await response[0].json();
  const responseData = response[0];
  const status = responseData.status as string;
  const desc_msg = responseData.desc_msg as string;

  return {
    status: status,
    message: desc_msg,
  };
};

export const sendApiChangedPassword = async (data: object) => {
  const formData = new FormData();
  formData.append("username", spbu);
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const response = await Promise.all([
    // fetch(`${baseUrl}/change_password`, {
    fetchRoute(`/change_password`, {
      method: "post",
      body: formData,
    }),
  ]);

  // const responseData = await response[0].json();
  const responseData = response[0];
  const status = responseData.status as string;
  const desc_msg = responseData.desc_msg as string;

  return {
    status: status,
    message: desc_msg,
  };
};

export const sendApiProfile = async (data: object) => {
  const formData = new FormData();
  formData.append("nospbu", spbu);
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const response = await Promise.all([
    // fetch(`${baseUrl}/change_password`, {
    fetchRoute(`/change_password`, {
      method: "post",
      body: formData,
    }),
  ]);

  // const responseData = await response[0].json();
  const responseData = response[0];
  const status = responseData.status as string;
  const desc_msg = responseData.desc_msg as string;

  return {
    status: status,
    message: desc_msg,
  };
};

export const sendApiCheckLists = async (data: object) => {
  const formData = new FormData();
  formData.append("no_spbu", spbu);

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === "object") formData.append(key, JSON.stringify(value));
    else formData.append(key, value);
  });

  const response = await Promise.all([
    // fetch(`${baseUrl}/send_survey`, {
    fetchRoute(`/send_survey`, {
      method: "post",
      body: formData,
    }),
  ]);

  // const responseData = await response[0].json();
  const responseData = response[0];
  const status = responseData.status as string;
  const desc_msg = responseData.desc_msg as string;

  return {
    status: status,
    message: desc_msg,
  };
};

export const sendApiTransportLossFormData = async (data: object) => {
  data = { ...data, ...{ spbu: spbu } };
  const formData = new FormData();

  const exceptFields = [
    "density_15c",
    "password",
    "state",
    "tank_name",
    "treshold_ttl_loss",
    "lolines_ids",
    "conf_tolerance",
    "conf_tolerance_discrepancy",
    "datas_fname",
    "datas_fname_atg",
    "datas_id",
    "datas_id_atg",
    "is_atg",
  ];
  const numberFields = [
    "density_obs",
    "delivery_discrepancy",
    "temperatur_obs",
    "vol_after",
    "volume_ar",
    "volume_sales",
    "ship_loss_id",
    "shipment_id",
    "height_after",
    "tank_id",
    "height_before",
    "ttl_loss",
    "claim_discrepancy",
    "sensitivity",
    "tolerance_discrepancy",
    "ttl_loss_claim",
    "tolerance",
    "vol_compartment",
    "vol_before",
  ];

  Object.entries(data).forEach(([key, value]) => {
    if (exceptFields.indexOf(key) == -1)
      formData.append(
        key,
        numberFields.indexOf(key) != -1 ? Number(value) : value
      );
  });

  console.log("send form offline data with api");
  // console.log(JSON.stringify(data));

  const response = await Promise.all([
    // fetch(`${baseUrl}/send_transportloss_ofline`, {
    fetchRoute(`/send_transportloss_ofline`, {
      method: "post",
      body: formData,
    }),
  ]);

  // const responseData = await response[0].json();
  const responseData = response[0];
  const status = responseData.status as string;
  const desc_msg = responseData.desc_msg as string;

  return {
    status: status,
    message: desc_msg,
  };
};
