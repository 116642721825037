import {
  getIsAuthenticatedStorage,
  getUserData,
  setIsAuthenticatedStorage,
  setUserData as setUserDataStorage,
} from "../storage";
import { ActionType } from "../../util/types";
import { User } from "../../models/User";
import { UserDataInterface } from "./user.state";

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-user-loading",
    isLoading,
  } as const);

export const setUserData = (data: User) => {
  setUserDataStorage(data);
  return {
    type: "set-user-data",
    data,
  } as const;
};

export const setIsAuthenticated =
  (authenticated: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setIsAuthenticatedStorage(authenticated);
    return {
      type: "set-is-authenticated",
      authenticated,
    } as const;
  };

export const setUsername =
  (user_name?: string) => async (dispatch: React.Dispatch<any>) => {
    return {
      type: "set-username",
      user_name,
    } as const;
  };

export const setAuthInfo = (authInfo: UserDataInterface) => {
  return {
    type: "set-auth-info",
    authInfo,
  } as const;
};

export const logInUser = () => async (dispatch: React.Dispatch<any>) => {
  return new Promise((resolve) => {
    let v = {
      initialized: true,
      loggedIn: true,
      user: true,
    };
    dispatch(setAuthInfo(v));

    // window.localStorage.setItem("AUTH", JSON.stringify(v.loggedIn));
    // setTimeout(() => {
    return resolve(true);
    // }, 1000);
  });
};

export const initializeUser = () => async (dispatch: React.Dispatch<any>) => {
  console.log("INItIALIZE");

  // let response = window.localStorage.getItem("AUTH") || null;
  let response = await getIsAuthenticatedStorage();
  // console.log(response);
  if (response) {
    let responseUser = await getUserData();

    dispatch(
      setAuthInfo({
        initialized: true,
        loggedIn: true,
        user: response,
      })
    );
  } else {
    dispatch(
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        user: null,
      })
    );
  }
};

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setIsAuthenticated(false));
  dispatch(setAuthInfo({ initialized: true, loggedIn: false, user: null }));
  dispatch(setUsername());
};

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setUserData>
  | ActionType<typeof setAuthInfo>
  | ActionType<typeof setIsAuthenticated>
  | ActionType<typeof setUsername>;
