import React from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router";

import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  camera,
  pin,
  chatbox,
  basket,
  archiveOutline,
  help,
  logOut,
  person,
  swapVerticalOutline,
  alarmOutline,
  readerOutline,
  alarm,
  reader,
  archive,
  water,
  helpCircleSharp,
  statsChartSharp,
  pieChartSharp,
  colorFillSharp,
  powerSharp,
} from "ionicons/icons";

import { connect } from "../data/connect";

import { useTranslation } from "react-i18next";

import "./Menu.css";

interface Pages {
  title: string;
  path: string;
  icon: string;
  color: string;
  routerDirection?: string;
}
interface StateProps {
  isAuthenticated: boolean;
  userName: string;
}

interface DispatchProps {}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps {}

const Menu: React.FC<MenuProps> = ({ history, isAuthenticated, userName }) => {
  const location = useLocation();
  const [t, i18n] = useTranslation("common");

  const routes = {
    deliveryPages: [
      {
        title: t("menus.delivery"),
        path: "/tabs/delivery",
        icon: archive,
        color: "tertiary",
      },
      {
        title: t("menus.your_orders"),
        path: "/tabs/order",
        icon: basket,
        color: "tertiary",
      },
      {
        title: t("menus.message_from_us"),
        path: "/tabs/message",
        icon: chatbox,
        color: "primary",
      },
      {
        title: t("menus.transport_loss"),
        path: "/tabs/loss",
        icon: water,
        color: "warning",
      },
      {
        title: t("menus.transport_loss_qrcode"),
        path: "/tabs/scan_rqcode",
        icon: camera,
        color: "medium",
      },
    ],
    operationInfoPages: [
      {
        title: t("menus.stock"),
        path: "/tabs/stock",
        icon: pieChartSharp,
        color: "medium",
      },
      {
        title: t("menus.sales"),
        path: "/tabs/sales",
        icon: statsChartSharp,
        color: "medium",
      },
      {
        title: t("menus.gain_loss"),
        path: "/tabs/gain_loss",
        icon: colorFillSharp,
        color: "medium",
      },
    ],
    accountPages: [
      {
        title: t("menus.help_feedback"),
        path: "/tabs/help_feedback",
        icon: helpCircleSharp,
        color: "tertiary",
      },
      {
        title: "Profile",
        path: "/tabs/profile",
        icon: person,
        color: "tertiary",
      },
      { title: "Logout", path: "/logout", icon: powerSharp, color: "danger" },
    ],
  };

  // console.log(location.pathname);

  function renderlistItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection="root"
            className={
              location.pathname.startsWith(p.path) ? "selected" : undefined
            }
          >
            <IonIcon
              style={{ marginInlineEnd: "12px" }}
              slot="start"
              icon={p.icon}
              color={p.color}
            />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" disabled={!isAuthenticated} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonToolbar>
          <IonTitle>
            <div className="menu-logo">
              <img src="assets/img/logo.png" alt="Ionic logo" />
              <h6>{userName}</h6>
            </div>
          </IonTitle>
        </IonToolbar>
        <div className="menu-list">
          <IonList lines="none">
            <IonListHeader>{t("menus.title_1")}</IonListHeader>
            {renderlistItems(routes.deliveryPages)}
          </IonList>
          <IonList lines="none">
            <IonListHeader>{t("menus.title_2")}</IonListHeader>
            {renderlistItems(routes.operationInfoPages)}
          </IonList>
          <IonList lines="none">
            <IonListHeader>{t("menus.title_3")}</IonListHeader>
            {renderlistItems(routes.accountPages)}
          </IonList>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isAuthenticated,
    userName: state.user.user_name,
  }),
  component: withRouter(Menu),
});
