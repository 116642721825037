import { UserActions } from "./user.actions";
import { UserState } from "./user.state";

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case "set-user-loading":
      return { ...state, loading: action.isLoading };
    case "set-user-data":
      return { ...state, userData: action.data };
    case "set-username":
      return { ...state, user_name: action.user_name! };
    case "set-is-authenticated":
      return { ...state, isAuthenticated: action.authenticated };
    case "set-auth-info":
      return { ...state, authInfo: action.authInfo };
  }
}
