import { Route, Redirect } from "react-router-dom";
import {
  IonApp,
  IonLoading,
  IonRouterOutlet,
  IonSplitPane,
  IonToast,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Login from "./pages/Login";
import MainTabs from "./pages/MainTabs";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/material-design-iconic-font/css/material-design-iconic-font.css";
import { AppContextProvider } from "./data/AppContext";
import { connect } from "./data/connect";
import {
  loadData,
  refreshWillSendCount,
  sendOfflineData,
  setOngoingDeliverysLoaded,
  setPastDeliverysLoaded,
  setFeedbackLoaded,
  setOrderLoaded,
  setServerMessage,
  setServerResStatus,
  setTransportLossAllLoaded,
} from "./data/data/data.actions";
import { Suspense, useEffect } from "react";
import React from "react";
import { ConnectionStatus, Network } from "@capacitor/network";
import {
  setUsername,
  setUserData,
  setIsAuthenticated,
  initializeUser,
  logoutUser,
} from "./data/user/user.actions";
import {
  getUserData,
  setIsAuthenticatedStorage,
  setUserData as setUserDataStorage,
} from "./data/storage";
import { putUserInfoInFormData } from "./data/api";
import RedirectToLogin from "./components/RedirectToLogin";
import ScanPage from "./pages/ScanPage";
import StockPage from "./pages/StockPage";
import SalePage from "./pages/SalePage";
import GainlossPage from "./pages/GainlossPage";
import Help from "./components/Help";
import Profile from "./components/Profile";
import { UserDataInterface } from "./data/user/user.state";

setupIonicReact({ swipeBackEnabled: false });

interface OwnProps {
  authenticated: boolean;
}

const App: React.FC<OwnProps> = ({ authenticated }) => {
  return (
    <AppContextProvider>
      <OdiAppConnected authenticated={authenticated} />
    </AppContextProvider>
  );
};

interface StateProps {
  responseStatus: string;
  message: string;
  isAuthenticated: boolean;
  authInfo: UserDataInterface;
}

interface DispatchProps {
  loadData: typeof loadData;
  refreshWillSendCount: typeof refreshWillSendCount;
  sendOfflineData: typeof sendOfflineData;
  setUserData: typeof setUserData;
  setUsername: typeof setUsername;
  setIsAuthenticated: typeof setIsAuthenticated;
  setOngoingDeliverysLoaded: typeof setOngoingDeliverysLoaded;
  setPastDeliverysLoaded: typeof setPastDeliverysLoaded;
  setOrderLoaded: typeof setOrderLoaded;
  setFeedbackLoaded: typeof setFeedbackLoaded;
  setTransportLossAllLoaded: typeof setTransportLossAllLoaded;
  setServerMessage: typeof setServerMessage;
  setServerResStatus: typeof setServerResStatus;
  initializeUser: typeof initializeUser;
  logoutUser: typeof logoutUser;
}

const OdiApp: React.FC<StateProps & DispatchProps & OwnProps> = ({
  setServerMessage,
  setServerResStatus,
  responseStatus,
  message,
  authenticated,
  isAuthenticated,
  authInfo,
  initializeUser,
  logoutUser,
  setIsAuthenticated,
  setOngoingDeliverysLoaded,
  setPastDeliverysLoaded,
  setOrderLoaded,
  setFeedbackLoaded,
  loadData,
  refreshWillSendCount,
  sendOfflineData,
  setUsername,
  setUserData: setUserDataAction,
}) => {
  // console.log("AUTH", isAuthenticated);
  const getCurrentNetworkStatus = async () => {
    const status = await Network.getStatus();

    return status.connected;
  };

  const onNetWorkStatus = (status: ConnectionStatus) => {
    console.log("Network status changed", status);

    if (status.connected) {
      sendOfflineData();
    } else {
      refreshWillSendCount();
    }
  };

  const checkNetWorkAndSend = async () => {
    const online = await getCurrentNetworkStatus();

    if (online) sendOfflineData();
  };

  const setUserDataFromStorage = async () => {
    const userData = await getUserData();
    setUserDataAction(userData);
    setUserDataStorage(userData);
    putUserInfoInFormData(userData);
    setUsername(userData.user_name);
  };

  useEffect(() => {
    Network.addListener("networkStatusChange", onNetWorkStatus);

    refreshWillSendCount();

    checkNetWorkAndSend();

    // console.log(authenticated);
    if (authenticated) {
      setIsAuthenticated(true);
      setUserDataFromStorage();
      loadData();
    }
  }, [authenticated]);

  useEffect(() => {
    // console.log("authInfo:", authInfo);

    // checkNetWorkAndSend();
    !authInfo?.initialized && (async () => await initializeUser())();
  }, [authInfo]);

  // console.log(authInfo);

  if (!authInfo || !authInfo.initialized) {
    return (
      <IonApp>
        <IonLoading isOpen={!authInfo ? true : false} />
      </IonApp>
    );
  } else {
    return (
      <IonApp>
        <Suspense fallback={<IonLoading isOpen={true} />}>
          <IonReactRouter>
            {authInfo.loggedIn === true ? (
              <IonSplitPane contentId="main">
                <Menu />
                <IonRouterOutlet id="main">
                  <Redirect to="/tabs" />
                  <Route path="/tabs" render={() => <MainTabs />} />
                  {/* <Route path="/login" component={Login} /> */}
                  {/* <Route exact path="/">
                      <Redirect to="/tabs" />
                    </Route> */}
                  <Route
                    path="/logout"
                    render={() => {
                      return (
                        <RedirectToLogin
                          logoutUser={logoutUser}
                          setUserData={setUserData}
                          setIsAuthenticated={setIsAuthenticated}
                          setOngoingDeliverysLoaded={setOngoingDeliverysLoaded}
                          setPastDeliverysLoaded={setPastDeliverysLoaded}
                          setOrderLoaded={setOrderLoaded}
                          setFeedbackLoaded={setFeedbackLoaded}
                          setTransportLossAllLoaded={setTransportLossAllLoaded}
                        />
                      );
                    }}
                  />
                </IonRouterOutlet>
              </IonSplitPane>
            ) : (
              <>
                <Route path="/login" component={Login} />
                <Redirect from="/" to="/login" exact />
              </>
            )}
          </IonReactRouter>
          <IonToast
            cssClass={
              responseStatus == "S"
                ? "success-toast"
                : responseStatus == "E"
                ? "fail-toast"
                : ""
            }
            isOpen={message !== "" && responseStatus !== ""}
            message={message}
            duration={5000}
            onDidDismiss={() => {
              setServerMessage("");
              setServerResStatus("");
            }}
          />
        </Suspense>
      </IonApp>
    );
  }
};
export default App;

const OdiAppConnected = connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    message: state.data.message,
    responseStatus: state.data.responseStatus,
    isAuthenticated: state.user.isAuthenticated,
    authInfo: state.user.authInfo,
  }),
  mapDispatchToProps: {
    loadData,
    sendOfflineData,
    refreshWillSendCount,
    initializeUser,
    logoutUser,
    setUserData,
    setUsername,
    setIsAuthenticated,
    setOngoingDeliverysLoaded,
    setPastDeliverysLoaded,
    setOrderLoaded,
    setFeedbackLoaded,
    setTransportLossAllLoaded,
    setServerMessage,
    setServerResStatus,
  },
  component: OdiApp,
});
