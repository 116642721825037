import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { connect } from "../../data/connect";
import { useTranslation } from "react-i18next";
import { Delivery } from "../../models/Delivery";
import { useForm } from "react-hook-form";
import { getFeedbackOptions } from "../../data/api";
import { FeedbackOption, FeedbackOptionAll } from "../../models/Feedback";
import {
  refreshFeedbackOptions,
  refreshFeedbacks,
  setResInfoAfterSend,
} from "../../data/data/data.actions";
import { sendFeedback } from "../../data/sync";

interface OwnProps {
  onDismissModal: () => void;
  delivery: Delivery;
}

interface StateProps {
  feedbackOptions: FeedbackOptionAll[];
  isLoadingFeedbackOptions: boolean;
}

interface DispatchProps {
  setResInfoAfterSend: typeof setResInfoAfterSend;
  refreshFeedbackOptions: typeof refreshFeedbackOptions;
  refreshFeedbacks: typeof refreshFeedbacks;
}

const SendFeedback: React.FC<OwnProps & StateProps & DispatchProps> = ({
  refreshFeedbacks,
  setResInfoAfterSend,
  onDismissModal,
  refreshFeedbackOptions,
  isLoadingFeedbackOptions,
  delivery,
  feedbackOptions,
}) => {
  const [isSending, setIsSending] = useState(false);
  const [t, i18n] = useTranslation("common");
  const [currentCode, setCurrentCode] = useState("");
  const [devSuggestions, setDevSuggestions] = useState<FeedbackOption[]>([]);
  const [complaintScopes, setComplaintScopes] = useState<FeedbackOption[]>([]);
  const [complaintCates, setComplaintCates] = useState<FeedbackOption[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const showError = (_fieldName: any) => {
    return (
      errors[_fieldName] && (
        <div
          style={{
            color: "red",
            padding: 5,
            paddingLeft: 12,
            fontSize: "smaller",
          }}
        >
          {errors[_fieldName].message || "This field is required"}
        </div>
      )
    );
  };

  const onSubmit = async (data: any) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value === undefined) value = "";
    });

    const moreData = {
      vehicle: delivery.vehicle,
      date: delivery.date_shipment,
      company_id: delivery.company_id,
      driver_code: delivery.driver_code,
      driver_assistant_code: delivery.driver_assistant_code,
      state: 1,
    };
    data = { ...data, ...moreData };

    if (data.rating === "C") {
      data.complaint = undefined;
      data.complaint_category = undefined;
    } else if (data.rating === "D" || data.rating === "E") {
      data.development_suggestions = undefined;
    } else {
      data.development_suggestions = undefined;
      data.complaint = undefined;
      data.complaint_category = undefined;
    }
    // alert(JSON.stringify(data, null, 2));
    setIsSending(true);
    const { msg, responseStatus } = await sendFeedback(data);
    await refreshFeedbacks();
    setIsSending(false);

    setResInfoAfterSend(msg, responseStatus);
    onDismissModal();
  };

  const ratingOptions = [
    {
      value: "A",
      option: "Sangat Baik",
    },
    {
      value: "B",
      option: "Baik",
    },
    {
      value: "C",
      option: "Cukup",
    },
    {
      value: "D",
      option: "Buruk",
    },
    {
      value: "E",
      option: "Sangat Buruk",
    },
  ];

  const changeOption = async (
    id: string | undefined,
    code: string | undefined
  ) => {
    if (code != undefined) {
      if (code === "A" || code === "B") {
        setDevSuggestions([]);
        setComplaintScopes([]);
        setComplaintCates([]);
      } else {
        const options = feedbackOptions.filter(
          (option) => option.code === code
        )[0];
        console.log(options);
        if (options.data.length == 0) return;
        if (options.data[0].id < 8) {
          setDevSuggestions(options.data);
          setComplaintScopes([]);
          setComplaintCates([]);
        } else if (options.data[0].id < 13) {
          setComplaintScopes(options.data);
          setDevSuggestions([]);
        }
      }
      setCurrentCode(code);
    }

    if (id != undefined) {
      const options = feedbackOptions.filter(
        (option) => option.code === currentCode
      )[0];
      if (options.data.length == 0) return;

      const childOptions = options.data.filter(
        (option) => option.id === Number(id)
      )[0];
      if (childOptions) {
        if (childOptions.child && childOptions.child.length == 0) return;
        if (childOptions.child) setComplaintCates(childOptions.child);
      }
      if (options.data[0].id > 7) {
        setDevSuggestions([]);
      }
    }
  };

  useEffect(() => {
    console.log(feedbackOptions);
    if (feedbackOptions.length === 0) {
      refreshFeedbackOptions(false);
    }
  }, [feedbackOptions]);

  console.log(feedbackOptions);

  return (
    <IonPage id="send-feedback">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("modal_feedback.title")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismissModal}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {isLoadingFeedbackOptions && (
          <div className="spin">
            <IonSpinner name="bubbles" color="primary" />
          </div>
        )}
        {!isLoadingFeedbackOptions && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonRow>
              <IonCol size="6">
                <IonText>
                  <strong>{t("modal_feedback.nopol")}</strong>
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonInput disabled>{delivery.vehicle}</IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonText>
                  <strong>{t("modal_feedback.tgl_validasi")}</strong>
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonInput disabled>{delivery.date_shipment}</IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonText>
                  <strong>{t("modal_feedback.pengirim")}</strong>
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonInput
                  {...register("sender", {
                    required: true,
                    minLength: { value: 4, message: "Must be 4 chars long" },
                  })}
                />
                {showError("sender")}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonText>
                  <strong>{t("modal_feedback.no_telepon")}</strong>
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonInput
                  type="number"
                  {...register("mobile", { required: true })}
                />
                {showError("mobile")}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonText>
                  <strong>{t("modal_feedback.rating_layanan")}</strong>
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonSelect
                  interface="popover"
                  onIonChange={(e) => changeOption(undefined, e.detail.value)}
                  {...register("rating", { required: true })}
                >
                  {ratingOptions.map((o, index) => (
                    <IonSelectOption
                      style={{ whiteSpace: "normal" }}
                      value={o.value}
                      key={"rating-" + index}
                    >
                      {o.option}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                {showError("rating")}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonText>
                  <strong>{t("modal_feedback.saran_pengembangan")}</strong>
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonSelect
                  interface="popover"
                  disabled={devSuggestions.length === 0}
                  onIonChange={(e) => changeOption(e.detail.value, undefined)}
                  {...register("development_suggestions", {
                    required: devSuggestions.length !== 0,
                  })}
                >
                  {devSuggestions.map((o, index) => (
                    <IonSelectOption
                      style={{ whiteSpace: "normal" }}
                      value={o.id}
                      key={"development_suggestions-" + index}
                    >
                      {o.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                {showError("development_suggestions")}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonText>
                  <strong>{t("modal_feedback.lingkup_keluhan")}</strong>
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonSelect
                  interface="popover"
                  disabled={complaintScopes.length === 0}
                  onIonChange={(e) => changeOption(e.detail.value, undefined)}
                  {...register("complaint", {
                    required: complaintScopes.length !== 0,
                  })}
                >
                  {complaintScopes.map((o, index) => (
                    <IonSelectOption
                      style={{ whiteSpace: "normal" }}
                      value={o.id}
                      key={"complaint-" + index}
                    >
                      {o.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                {showError("complaint")}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonText>
                  <strong>{t("modal_feedback.kategori_keluhan")}</strong>
                </IonText>
              </IonCol>
              <IonCol size="6">
                <IonSelect
                  interface="popover"
                  disabled={complaintCates.length === 0}
                  onIonChange={(e) => changeOption(e.detail.value, undefined)}
                  {...register("complaint_category", {
                    required: complaintCates.length !== 0,
                  })}
                >
                  {complaintCates.map((o, index) => (
                    <IonSelectOption
                      style={{ whiteSpace: "normal" }}
                      value={o.id}
                      key={"complaint_category-" + index}
                    >
                      {o.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                {showError("complaint_category")}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonText>
                  <strong>{t("modal_feedback.message")}</strong>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonTextarea
                  {...register("message", {
                    required: true,
                    minLength: {
                      value: 20,
                      message: "Must be more than 20 letters",
                    },
                  })}
                ></IonTextarea>
                {showError("message")}
              </IonCol>
            </IonRow>

            <IonButton type="submit" color="primary" expand="block">
              {isSending && <IonSpinner name="bubbles" color="light" />}
              {t("modal_feedback.submit")}
            </IonButton>
          </form>
        )}
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    feedbackOptions: state.data.feedbackOptions,
    isLoadingFeedbackOptions: state.data.dataLoadingFeedbackOptions,
  }),
  mapDispatchToProps: {
    setResInfoAfterSend,
    refreshFeedbacks,
    refreshFeedbackOptions,
  },
  component: React.memo(SendFeedback),
});
