import { IonItem, IonLabel } from "@ionic/react";
import TimeAgo from "./TimeAgo";
import "./SyncStatus.scss";
import moment from "moment";

interface SyncStatusInterface {
  lastUpdate: string;
  message: string;
}

const SyncStatus: React.FC<SyncStatusInterface> = ({ lastUpdate, message }) => {
  // console.log(lastUpdate);
  const dataDate = moment(lastUpdate);
  return (
    <IonItem lines="full" id="item-sync">
      <IonLabel className="label-sync">
        {dataDate.isValid() && (
          <>
            Last Synched on <TimeAgo timestamp={lastUpdate} />,{" "}
          </>
        )}
        <span className="span-sync">{message}</span>
      </IonLabel>
    </IonItem>
  );
};

export default SyncStatus;
