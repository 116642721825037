import { Network } from "@capacitor/network";
import {
  getApiCheckLists,
  getApiDelivery,
  getApiDriverDetails,
  getApiFeedbackOfflineOptions,
  getApiFeedbacks,
  getApiJustify,
  getApiLossFormOffineData,
  getApiOrders,
  getApiTanks,
  getApiTransportLossAll,
  getApiVehicleDatails,
  sendApiChangedPassword,
  sendApiCheckLists,
  sendApiFeedback,
  sendApiProfile,
  sendApiTransportLossFormData,
  sendApiFeedbackStatus,
  getApiOngoingDelivery,
  getApiPastDelivery,
} from "./api";
import {
  getStorageChecklists,
  getStorageDeliverys,
  getStorageDriverDetails,
  getStorageFeedbackOfflineOptions,
  getStorageFeedbacks,
  getStorageJustify,
  getStorageOngoingDeliverys,
  getStorageOrders,
  getStoragePastDeliverys,
  getStorageStack,
  getStorageTankOptions,
  getStorageTransportLossAll,
  getStorageTransportLossOffline,
  getStorageVehicleDatails,
  initStorageDriverDetails,
  initStorageFeedbackOfflineOptions,
  initStorageFeedbacks,
  initStorageOngoingDeliverys,
  initStorageOrders,
  initStoragePastDeliverys,
  initStorageStack,
  initStorageTransportLossAll,
  initStorageTransportLossOffline,
  initStorageVehicleDetails,
  saveStorageStack,
  setChecklists,
  setDeliverys,
  setDriverDetails,
  setFeedback,
  setFeedbackOfflineOptions,
  setJustify,
  setOrder,
  setStorageOngoingDeliverys,
  setStoragePastDeliverys,
  setTankOptions,
  setTransportLossAll,
  setTransportLossOffline,
  setVehicleDatails,
  updateStorageTransportLossOffline,
} from "./storage";

export const getCurrentNetworkStatus = async () => {
  const status = await Network.getStatus();

  return status.connected;
};

export const getDifferenceBetweenArray = (
  latestData: Array<any> | undefined,
  newData: Array<any> | undefined,
  selectedItem: string
) => {
  return latestData === undefined || latestData.length === 0
    ? newData?.length
    : latestData.filter((object1: any) => {
        return !newData?.some((object2: any) => {
          return object1[selectedItem] === object2[selectedItem];
        });
      }).length;
};

export const getJustify = async (isofline: boolean) => {
  let justify = undefined;
  let latestJustify = await getStorageJustify();
  let justifyResponse = {
    justifyMessage: "",
    justifyStatus: "S",
  };

  if (isofline) {
    justify = latestJustify;
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const justifyResponseApi = await getApiJustify();
        justify = justifyResponseApi;
        justifyResponse = justifyResponseApi.justifyResponse;
        if (
          justifyResponse.justifyStatus === "E" &&
          justify.justify.length === 0
        ) {
          justify.justify = latestJustify.justify;
        }
        await setJustify(justify);
      } catch (err) {
        justify = latestJustify;
      }
    } else {
      justify = latestJustify;
    }
  }

  return { justify, justifyResponse };
};

export const getTanks = async (isofline: boolean) => {
  let tank = undefined;
  let latestTank = await getStorageTankOptions();
  let tankResponse = {
    tanksMessage: "",
    tanksStatus: "S",
  };
  if (isofline) {
    tank = latestTank;
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const tankResponseApi = await getApiTanks();
        tank = tankResponseApi;
        tankResponse = tankResponseApi.tanksResponse;
        if (tankResponse.tanksStatus === "E" && tank.tanks.length === 0) {
          tank.tanks = latestTank.tanks;
        }
        await setTankOptions(tank);
      } catch (err) {
        tank = latestTank;
      }
    } else {
      tank = latestTank;
    }
  }

  return { tank, tankResponse };
};

export const getCheckLists = async (isofline: boolean) => {
  let checkList = undefined;
  let latestChecklist = await getStorageChecklists();
  let checklistResponse = {
    checkListsMessage: "",
    checkListsStatus: "S",
  };

  if (isofline) {
    checkList = latestChecklist;
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const checklistResponseApi = await getApiCheckLists();
        checkList = checklistResponseApi;
        checklistResponse = checklistResponseApi.checkListsResponse;
        if (
          checklistResponse.checkListsStatus === "E" &&
          checkList.checkLists.length === 0
        ) {
          checkList.checkLists = latestChecklist.checkLists;
        }
        // console.log(checklist);
        await setChecklists(checkList);
      } catch (err) {
        checkList = latestChecklist;
      }
    } else {
      checkList = latestChecklist;
    }
  }
  return { checkList, checklistResponse };
};

export const getOngoingDelivery = async (isofline: boolean) => {
  let ongoingDelivery = undefined;
  let latestOngoingDelivery = await getStorageOngoingDeliverys();
  let ongoingDeliveryResponse = {
    ongoingDeliveryMessage: `${
      !!latestOngoingDelivery
        ? latestOngoingDelivery.ongoingDeliverys.length
        : 0
    } data downloaded`,
    ongoingDeliveryStatus: "S",
  };
  // console.log("ERROR");

  if (isofline) {
    ongoingDelivery = latestOngoingDelivery;
    if (ongoingDelivery) {
    } else {
      if (await getCurrentNetworkStatus()) {
        try {
          const deliveryResponseApi = await getApiOngoingDelivery();
          ongoingDelivery = deliveryResponseApi.deliverysData;
          ongoingDeliveryResponse = deliveryResponseApi.ongoingDataResponse;

          if (ongoingDeliveryResponse.ongoingDeliveryStatus === "S") {
            ongoingDeliveryResponse.ongoingDeliveryMessage = `${ongoingDelivery.ongoingDeliverys.length} data downloaded`;
          }
          if (
            ongoingDeliveryResponse.ongoingDeliveryStatus === "E" &&
            ongoingDelivery.ongoingDeliverys.length === 0
          ) {
            ongoingDelivery.ongoingDeliverys =
              latestOngoingDelivery.ongoingDeliverys;
          }

          await setStorageOngoingDeliverys(ongoingDelivery);
        } catch (err) {
          console.log(`Display Error : \n ${err}`);
          ongoingDelivery = latestOngoingDelivery;
        }
      }
    }
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const deliveryResponseApi = await getApiOngoingDelivery();
        ongoingDelivery = deliveryResponseApi.deliverysData;
        ongoingDeliveryResponse = deliveryResponseApi.ongoingDataResponse;

        if (ongoingDeliveryResponse.ongoingDeliveryStatus === "S") {
          ongoingDeliveryResponse.ongoingDeliveryMessage = `${ongoingDelivery.ongoingDeliverys.length} data downloaded`;
        }
        if (
          ongoingDeliveryResponse.ongoingDeliveryStatus === "E" &&
          ongoingDelivery.ongoingDeliverys.length === 0
        ) {
          ongoingDelivery.ongoingDeliverys =
            latestOngoingDelivery.ongoingDeliverys;
        }

        await setStorageOngoingDeliverys(ongoingDelivery);
      } catch (err) {
        console.log(`Display Error : \n ${err}`);
        ongoingDelivery = latestOngoingDelivery;
      }
    } else {
      ongoingDelivery = latestOngoingDelivery;
    }
  }
  return { ongoingDelivery, ongoingDeliveryResponse };
};

export const getPastDelivery = async (isofline: boolean) => {
  let pastDelivery = undefined;
  let latestPastDelivery = await getStoragePastDeliverys();
  let pastDeliveryResponse = {
    pastDeliveryMessage: `${
      !!latestPastDelivery ? latestPastDelivery.pastDeliverys.length : 0
    } data downloaded`,
    pastDeliveryStatus: "S",
  };
  // console.log("ERROR");

  if (isofline) {
    pastDelivery = latestPastDelivery;
    if (pastDelivery) {
    } else {
      if (await getCurrentNetworkStatus()) {
        try {
          const pastDeliveryResponseApi = await getApiPastDelivery();
          pastDelivery = pastDeliveryResponseApi.deliverysData;
          pastDeliveryResponse = pastDeliveryResponseApi.pastDataResponse;

          // console.log(pastDeliveryResponseApi);
          if (pastDeliveryResponse.pastDeliveryStatus === "S") {
            pastDeliveryResponse.pastDeliveryMessage = `${pastDelivery.pastDeliverys.length} data downloaded`;
          }
          if (
            pastDeliveryResponse.pastDeliveryStatus === "E" &&
            pastDelivery.pastDeliverys.length === 0
          ) {
            pastDelivery.pastDeliverys = latestPastDelivery.pastDeliverys;
          }

          await setStoragePastDeliverys(pastDelivery);
        } catch (err) {
          console.log(`Display Error : \n ${err}`);
          pastDelivery = latestPastDelivery;
        }
      }
    }
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const pastDeliveryResponseApi = await getApiPastDelivery();
        pastDelivery = pastDeliveryResponseApi.deliverysData;
        pastDeliveryResponse = pastDeliveryResponseApi.pastDataResponse;
        // console.log(pastDeliveryResponseApi);
        if (pastDeliveryResponse.pastDeliveryStatus === "S") {
          pastDeliveryResponse.pastDeliveryMessage = `${pastDelivery.pastDeliverys.length} data downloaded`;
        }
        if (
          pastDeliveryResponse.pastDeliveryStatus === "E" &&
          pastDelivery.pastDeliverys.length === 0
        ) {
          pastDelivery.pastDeliverys = latestPastDelivery.pastDeliverys;
        }

        await setStoragePastDeliverys(pastDelivery);
      } catch (err) {
        console.log(`Display Error : \n ${err}`);
        pastDelivery = latestPastDelivery;
      }
    } else {
      pastDelivery = latestPastDelivery;
    }
  }
  return { pastDelivery, pastDeliveryResponse };
};

export const getDelivery = async (isofline: boolean) => {
  let delivery = undefined;
  let latestDelivery = await getStorageDeliverys();

  let ongoingDeliveryResponse = {
    ongoingDeliveryMessage: `${
      !!latestDelivery ? latestDelivery.delivery.ongoingDeliverys.length : 0
    } data downloaded`,
    ongoingDeliveryStatus: "S",
  };

  let pastDeliveryResponse = {
    pastDeliveryMessage: `${
      !!latestDelivery ? latestDelivery.delivery.pastDeliverys.length : 0
    } data downloaded`,
    pastDeliveryStatus: "S",
  };
  if (isofline) {
    delivery = latestDelivery;
    if (delivery) {
    } else {
      if (await getCurrentNetworkStatus()) {
        try {
          const deliveryResponse = await getApiDelivery();
          delivery = deliveryResponse.deliverysData;

          if (ongoingDeliveryResponse.ongoingDeliveryStatus === "S") {
            ongoingDeliveryResponse.ongoingDeliveryMessage = `${delivery.delivery.ongoingDeliverys.length} data downloaded`;
          }
          if (pastDeliveryResponse.pastDeliveryStatus === "S") {
            pastDeliveryResponse.pastDeliveryMessage = `${delivery.delivery.pastDeliverys.length} data downloaded`;
          }
          ongoingDeliveryResponse = deliveryResponse.ongoingDataResponse;
          pastDeliveryResponse = deliveryResponse.pastDataResponse;

          await setDeliverys(delivery);
        } catch (err) {
          console.log(`Display Error : \n ${err}`);
          delivery = latestDelivery;
        }
      }
    }
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const deliveryResponse = await getApiDelivery();
        delivery = deliveryResponse.deliverysData;
        ongoingDeliveryResponse = deliveryResponse.ongoingDataResponse;
        pastDeliveryResponse = deliveryResponse.pastDataResponse;

        // console.log("ONGOING DELIVERYS", delivery.delivery.ongoingDeliverys);
        // console.log("LATEST ONGOING DELIVERYS", latestDelivery);
        if (ongoingDeliveryResponse.ongoingDeliveryStatus === "S") {
          ongoingDeliveryResponse.ongoingDeliveryMessage = `${delivery.delivery.ongoingDeliverys.length} data downloaded`;
        }

        if (pastDeliveryResponse.pastDeliveryStatus === "S") {
          pastDeliveryResponse.pastDeliveryMessage = `${delivery.delivery.pastDeliverys.length} data downloaded`;
        }

        await setDeliverys(delivery);
      } catch (err) {
        console.log(`Display Error : \n ${err}`);
        delivery = latestDelivery;
      }
    } else {
      delivery = latestDelivery;
    }
  }
  return { delivery, ongoingDeliveryResponse, pastDeliveryResponse };
};

export const getDriverDetails = async (
  driverIDs: Array<any>,
  isofline: boolean
) => {
  let driverDetails = undefined;
  let driverDetailsStorage = await getStorageDriverDetails();
  if (isofline) {
    driverDetails = driverDetailsStorage;
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const driverDetailsApi = await getApiDriverDetails(driverIDs);
        driverDetails = {
          drivers: [
            ...driverDetailsApi.drivers,
            ...driverDetailsStorage?.drivers,
          ],
        };
        await setDriverDetails(driverDetails);
      } catch (err) {
        driverDetails = driverDetailsStorage;
      }
    } else {
      driverDetails = driverDetailsStorage;
    }
  }

  return driverDetails;
};

export const getVehicleDetails = async (
  vehicleIDs: Array<any>,
  isofline: boolean
) => {
  let vehicleDetails = undefined;
  let vehicleDetailsStorage = await getStorageVehicleDatails();
  if (isofline) {
    vehicleDetails = vehicleDetailsStorage;
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const vehicleDetailsApi = await getApiVehicleDatails(vehicleIDs);
        vehicleDetails = {
          vehicles: [
            ...vehicleDetailsApi.vehicles,
            ...vehicleDetailsStorage?.vehicles,
          ],
        };
        await setVehicleDatails(vehicleDetails);
      } catch (err) {
        vehicleDetails = vehicleDetailsStorage;
      }
    } else {
      vehicleDetails = vehicleDetailsStorage;
    }
  }

  return vehicleDetails;
};

export const getLossFormOfflineData = async (
  shipIds: Array<any>,
  isofline: boolean
) => {
  let lossFormOfflineData = undefined;
  let lossFormOfflineStorageData = await getStorageTransportLossOffline();
  if (isofline) {
    lossFormOfflineData = lossFormOfflineStorageData;
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const lossFormOfflineApiData = await getApiLossFormOffineData(shipIds);
        lossFormOfflineData = {
          transFormOfflineDatas: [
            ...lossFormOfflineApiData.transFormOfflineDatas,
            ...lossFormOfflineStorageData.transFormOfflineDatas,
          ],
        };
        // console.log(lossFormOfflineData);
        await setTransportLossOffline(lossFormOfflineData);
      } catch (err) {
        lossFormOfflineData = lossFormOfflineStorageData;
      }
    } else {
      lossFormOfflineData = lossFormOfflineStorageData;
    }
  }

  return lossFormOfflineData;
};

export const getOrders = async (isofline: boolean) => {
  let order = undefined;
  let latestOrder = await getStorageOrders();
  let orderResponse = {
    orderMessage: `${
      !!latestOrder ? latestOrder.orders.length : 0
    } data downloaded`,
    orderStatus: "S",
  };

  // console.log("OFFLINE", isofline);
  if (isofline) {
    order = latestOrder;
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const orderResponseApi = await getApiOrders();
        order = {
          orders: orderResponseApi.orders,
          lastUpdateOrders: orderResponseApi.lastUpdateOrders,
        };
        orderResponse = orderResponseApi.orderResponse;

        if (orderResponse.orderStatus === "S") {
          orderResponse.orderMessage = `${orderResponseApi.orders.length} data downloaded`;
        } else if (
          orderResponse.orderStatus === "E" &&
          orderResponseApi.orders.length === 0
        ) {
          order.orders = latestOrder.orders;
        }
        await setOrder(order);
      } catch (err) {
        order = latestOrder;
      }
    } else {
      order = latestOrder;
    }
  }

  return { order, orderResponse };
};

export const getFeedbacks = async (isofline: boolean) => {
  let feedbacks = undefined;
  let latestFeedbacks = await getStorageFeedbacks();
  let feedbacksResponse = {
    feedbackMessage: `${
      !!latestFeedbacks ? latestFeedbacks.feedbacks.length : 0
    } data downloaded`,
    feedbackStatus: "S",
  };

  if (isofline) {
    feedbacks = latestFeedbacks;
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const feedbacksResponseApi = await getApiFeedbacks();
        feedbacks = {
          feedbacks: feedbacksResponseApi.feedbacks,
          lastUpdateFeedbacks: feedbacksResponseApi.lastUpdateFeedbacks,
        };
        feedbacksResponse = feedbacksResponseApi.feedbackResponse;

        if (feedbacksResponse.feedbackStatus === "S") {
          feedbacksResponse.feedbackMessage = `${feedbacksResponseApi.feedbacks.length} data downloaded`;
        } else if (
          feedbacksResponse.feedbackStatus === "E" &&
          feedbacksResponseApi.feedbacks.length === 0
        ) {
          feedbacks.feedbacks = latestFeedbacks.feedbacks;
        }

        await setFeedback(feedbacks);
      } catch (err) {
        feedbacks = latestFeedbacks;
      }
    } else {
      feedbacks = latestFeedbacks;
    }
  }

  return { feedbacks, feedbacksResponse };
};

export const getTransportLossAll = async (isofline: boolean) => {
  let transportlossAll = undefined;
  let latestTransportLossAll = await getStorageTransportLossAll();
  // console.log("LATEST TRANSPORT LOST", latestTransportLossAll);
  let transportLossAllResponse = {
    transportLossAllMessage: `${
      !!latestTransportLossAll ? latestTransportLossAll.transLossAll.length : 0
    } data downloaded`,
    transportLossAllStatus: "S",
  };

  if (isofline) {
    transportlossAll = latestTransportLossAll;
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        const transportlossAllResponseApi = await getApiTransportLossAll();
        transportlossAll = {
          transLossAll: transportlossAllResponseApi.transLossAll,
          lastUpdateTransportLossAll:
            transportlossAllResponseApi.lastUpdateTransportLossAll,
        };
        transportLossAllResponse =
          transportlossAllResponseApi.transportLossAllResponse;

        if (transportLossAllResponse.transportLossAllStatus === "S") {
          transportLossAllResponse.transportLossAllMessage = `${transportlossAllResponseApi.transLossAll.length} data downloaded`;
        } else if (
          transportLossAllResponse.transportLossAllStatus === "E" &&
          transportlossAllResponseApi.transLossAll.length === 0
        ) {
          transportlossAll.transLossAll = latestTransportLossAll.transLossAll;
        }

        await setTransportLossAll(transportlossAll);
      } catch (err) {
        transportlossAll = latestTransportLossAll;
      }
    } else {
      transportlossAll = latestTransportLossAll;
    }
  }

  return { transportlossAll, transportLossAllResponse };
};

export const getFeedbackOfflineOptions = async (isofline: boolean) => {
  let feedbackOptions = undefined;
  if (isofline) {
    feedbackOptions = await getStorageFeedbackOfflineOptions();
  } else {
    if (await getCurrentNetworkStatus()) {
      try {
        feedbackOptions = await getApiFeedbackOfflineOptions();
        await setFeedbackOfflineOptions(feedbackOptions);
      } catch (err) {
        feedbackOptions = await getStorageFeedbackOfflineOptions();
      }
    } else {
      feedbackOptions = await getStorageFeedbackOfflineOptions();
    }
  }

  return feedbackOptions;
};

export const sendFeedback = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const { message, status } = await sendApiFeedback(data);
      msg = message;
      responseStatus = status;
    } catch (err) {
      await saveStorageStack("feedback", data);
      msg =
        "Error is occured with Network, So Datas are saved to Storage temporarily";
      responseStatus = "E";
    }
  } else {
    await saveStorageStack("feedback", data);
    msg = "Datas are saved to Storage temporarily";
    responseStatus = "S";
  }
  return { msg, responseStatus };
};

export const sendFeedbackStatus = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const { message, status } = await sendApiFeedbackStatus(data);
      msg = message;
      responseStatus = status;
    } catch (err) {
      msg =
        "Error is occured with Network, So Datas are saved to Storage temporarily";
      responseStatus = "E";
    }
  } else {
    await saveStorageStack("feedback", data);
    msg = "Datas are saved to Storage temporarily";
    responseStatus = "S";
  }
  return { msg, responseStatus };
};

export const sendCheckLists = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const { message, status } = await sendApiCheckLists(data);
      msg = message;
      responseStatus = status;
    } catch (err) {
      await saveStorageStack("checklist", data);
      msg =
        "Error is occured with Network, So Datas are saved to Storage temporarily";
      responseStatus = "E";
    }
  } else {
    await saveStorageStack("checklist", data);
    msg = "Datas are saved to Storage temporarily";
    responseStatus = "S";
  }
  return { msg, responseStatus };
};

export const sendProfile = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const { message, status } = await sendApiProfile(data);
      msg = message;
      responseStatus = status;
    } catch (err) {
      await saveStorageStack("profile", data);
      msg =
        "Error is occured with Network, So Datas are saved to Storage temporarily";
      responseStatus = "E";
    }
  } else {
    await saveStorageStack("profile", data);
    msg = "Datas are saved to Storage temporarily";
    responseStatus = "S";
  }
  return { msg, responseStatus };
};

export const sendChangedPassword = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const { message, status } = await sendApiChangedPassword(data);
      msg = message;
      responseStatus = status;
    } catch (err) {
      msg = "Error is occured with Network, Retry later";
      responseStatus = "E";
    }
  } else {
    await saveStorageStack("profile", data);
    msg = "Offline now, Retry later";
    responseStatus = "E";
  }
  return { msg, responseStatus };
};

export const sendTransportLossFormData = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const { message, status } = await sendApiTransportLossFormData(data);
      msg = message;
      responseStatus = status;
    } catch (err) {
      await saveStorageStack("transportLoss", data);
      msg =
        "Error is occured with Network, So Datas are saved to Storage temporarily";
      responseStatus = "E";
    }
  } else {
    await saveStorageStack("transportLoss", data);
    msg = "Datas are saved to Storage temporarily";
    responseStatus = "S";
  }

  return { msg, responseStatus };
};

export const sendOfflineStackData = async () => {
  let success = true;
  const datas = await getStorageStack();
  let msg = "Datas are sent successfully";
  let responseStatus = "S";

  if (datas == null) return;

  try {
    Object.entries(datas).forEach(async ([key, value]) => {
      if (key === "profile") {
        console.log("send profile from offine stack");
      } else if (key === "feedback" && Array.isArray(value)) {
        value.map(async (feedback: any) => {
          console.log("send feedback from offine stack");

          try {
            const { status } = await sendApiFeedback(feedback);
          } catch (err) {
            throw Error();
          }
        });
      } else if (key === "transportLoss" && Array.isArray(value)) {
        value.map(async (data: any) => {
          console.log("send transportloss calculation data from offine stack");

          try {
            const { status } = await sendApiTransportLossFormData(data);
          } catch (err) {
            throw Error();
          }
        });
      } else if (key === "checklist" && Array.isArray(value)) {
        value.map(async (list: any) => {
          console.log("send checklist from offine stack");

          try {
            const { status } = await sendApiFeedback(list);
          } catch (err) {
            throw Error();
          }
        });
      }
    });
  } catch (err) {
    success = false;
  }

  if (success) initStorageStack();
};

export const getOfflineStackCount = async () => {
  let count = 0;

  const datas = await getStorageStack();

  if (datas == null) return 0;

  Object.entries(datas).forEach(async ([key, value]) => {
    if (key === "profile" && value !== "") {
      ++count;
    } else if (key === "feedback" && Array.isArray(value)) {
      count += value.length;
    } else if (key === "transportLoss" && Array.isArray(value)) {
      count += value.length;
    } else if (key === "checklist" && Array.isArray(value)) {
      count += value.length;
    }
  });

  return count;
};

export const cleanStorage = async () => {
  let delivery = {};
  let order = { lastUpdateOrders: "", orders: [] };
  let feedbacks = {};
  let transportlossAll = {};
  let checklist = {};
  let tank = {};
  let justify = {};
  let lossFormOfflineData = {};
  let feedbackOptions = {};
  let driverDetails = {};
  let vehicleDetails = {};

  if (await getCurrentNetworkStatus()) {
    try {
      await initStorageOngoingDeliverys();
      await initStoragePastDeliverys();
      await initStorageOrders();
      await initStorageFeedbacks();
      await initStorageTransportLossAll();
      await setChecklists(checklist);
      await setTankOptions(tank);
      await setJustify(justify);
      await initStorageTransportLossOffline();
      await initStorageFeedbackOfflineOptions();
      await initStorageDriverDetails();
      await initStorageVehicleDetails();
      // await setDriverDetails(driverDetails);
      // await setVehicleDatails(vehicleDetails);
      return true;
    } catch (err) {
      console.log(`Display Error : \n ${err}`);
      return false;
    }
  } else {
    return false;
  }
};
