import React, { useState, useRef, useEffect } from "react";

import {
  IonListHeader,
  IonList,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonHeader,
  getConfig,
  IonSpinner,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { search, syncSharp } from "ionicons/icons";

import * as selectors from "../data/selectors";
import { connect } from "../data/connect";
import { Feedback } from "../models/Feedback";
import FeedbackItem from "../components/ListItem/FeedbackItem";
import { setSearchText, refreshFeedbacks2 } from "../data/data/data.actions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TimeAgo from "../components/TimeAgo";
import SyncStatus from "../components/SyncStatus";

interface OwnProps {}

interface StateProps {
  feedbackList: Feedback[];
  mode: "ios" | "md";
  lastUpdateFeedbacks: string;
  feedbackMessage: string;
  isLoading: boolean;
  isLoadingFeedback: boolean;
  isFeedbackLoaded: true | null;
}

interface DispatchProps {
  setSearchText: typeof setSearchText;
  refreshFeedbacks2: typeof refreshFeedbacks2;
}

type FeedbackPageProps = OwnProps & StateProps & DispatchProps;

const FeedbackPage: React.FC<FeedbackPageProps> = ({
  isLoading,
  lastUpdateFeedbacks,
  feedbackMessage,
  isLoadingFeedback,
  isFeedbackLoaded,
  feedbackList,
  mode,
  setSearchText,
  refreshFeedbacks2,
}) => {
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);

  const [t, i18n] = useTranslation("common");

  const pageRef = useRef<HTMLElement>(null);

  const ios = mode === "ios";

  const onRefreshFeedbacks2 = async (e?: React.FormEvent) => {
    e?.preventDefault();
    await refreshFeedbacks2();
  };

  useEffect(() => {
    if (
      !isFeedbackLoaded &&
      !isLoadingFeedback
      // && feedbackList.length === 0
    ) {
      onRefreshFeedbacks2();
    }
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     refreshFeedbacks2();
  //   }, 300000); // 60,000 milliseconds = 1 minute

  //   return () => {
  //     // Clean up the interval when the component unmounts
  //     clearInterval(intervalId);
  //   };
  // }, []);

  // console.log(lastUpdateFeedbacks);

  return (
    <IonPage ref={pageRef}>
      <IonHeader translucent={true}>
        <IonToolbar>
          {!showSearchbar && (
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          )}
          {!ios && !showSearchbar && (
            <IonTitle>{t("menus.message_from_us")}</IonTitle>
          )}
          {showSearchbar && (
            <IonSearchbar
              showCancelButton="always"
              placeholder="Search"
              onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
            ></IonSearchbar>
          )}

          <IonButtons slot="end">
            {!ios && !showSearchbar && (
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        {/* {isLoadingFeedback && (
          <div className="spin">
            <IonSpinner name="bubbles" color="primary" />
          </div>
        )}
        {!isLoadingFeedback && ( */}
        <>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{t("menus.message_from_us")}</IonTitle>
            </IonToolbar>
            <IonToolbar>
              <IonSearchbar
                placeholder="Search"
                onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              ></IonSearchbar>
            </IonToolbar>
          </IonHeader>

          <div className="button-container">
            <IonButton
              onClick={onRefreshFeedbacks2}
              expand="full"
              color="primary"
            >
              <IonIcon icon={syncSharp}></IonIcon>
            </IonButton>
          </div>

          <SyncStatus
            lastUpdate={lastUpdateFeedbacks}
            message={feedbackMessage}
          />

          {/* {isLoadingFeedback && ( */}
          <div
            className={`${
              isLoadingFeedback ? "refreshing" : ""
            } spinner-overlay`}
          >
            <IonSpinner name="bubbles" color="primary" />
            {/* <span className="span-loading">{t("global.loading_download")}</span> */}
            <span className="span-loading">Downloading new data</span>
          </div>
          {/* )} */}

          <IonList
            className={`${
              isLoadingFeedback ? "refreshing" : "resetting"
            } list-container`}
          >
            {feedbackList.length === 0 && (
              <IonListHeader>No Feedbacks Found</IonListHeader>
            )}

            {feedbackList.length !== 0 &&
              feedbackList.map((list: Feedback, index: number) => (
                <FeedbackItem
                  feedback={list}
                  listType={list.Status.toLowerCase()}
                  key={`feedback-${index}`}
                />
              ))}
          </IonList>
        </>
        {/* )} */}
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isLoading: state.data.dataLoading,
    isLoadingFeedback: state.data.dataLoadingFeedback,
    isFeedbackLoaded: state.data._feedbackLoaded,
    lastUpdateFeedbacks: state.data.lastUpdateFeedbacks,
    feedbackMessage: state.data.feedbackMessage,
    feedbackList: selectors.getSearchedFeedbacks(state),
    mode: getConfig()!.get("mode"),
  }),
  mapDispatchToProps: {
    setSearchText,
    refreshFeedbacks2,
  },
  component: React.memo(FeedbackPage),
});
