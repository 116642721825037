import React, { useState, useRef, useEffect } from "react";

import {
  IonListHeader,
  IonList,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonHeader,
  getConfig,
  IonSpinner,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { search, syncSharp } from "ionicons/icons";
import i18next from "i18next";
import moment from "moment";
import * as selectors from "../data/selectors";
import { connect } from "../data/connect";
import { Order } from "../models/Order";
import OrderItem from "../components/ListItem/OrderItem";
import { setSearchText, refreshOrders } from "../data/data/data.actions";
import TimeAgo from "../components/TimeAgo";
import SyncStatus from "../components/SyncStatus";
import { useTranslation } from "react-i18next";

interface OwnProps {}

interface StateProps {
  orderList: Order[];
  orderDataLastUpdate: string;
  mode: "ios" | "md";
  orderMessage: string;
  isLoading: boolean;
  isLoadingOrder: boolean;
  isOrderLoaded: true | null;
}

interface DispatchProps {
  setSearchText: typeof setSearchText;
  refreshOrders: typeof refreshOrders;
}

type OrderPageProps = OwnProps & StateProps & DispatchProps;

const OrderPage: React.FC<OrderPageProps> = ({
  isLoading,
  orderDataLastUpdate,
  orderMessage,
  isLoadingOrder,
  isOrderLoaded,
  orderList,
  mode,
  setSearchText,
  refreshOrders,
}) => {
  var idLocale = require("moment/locale/id");
  var enLocale = require("moment/locale/en-gb");
  if (i18next.language === "id") {
    moment.locale("id", idLocale);
  } else {
    moment.locale("en-gb", enLocale);
  }

  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [t, i18n] = useTranslation("common");
  const pageRef = useRef<HTMLElement>(null);

  const ios = mode === "ios";

  const onRefreshOrders = async (e?: React.FormEvent) => {
    e?.preventDefault();
    await refreshOrders();
  };

  useEffect(() => {
    // console.log(isOrderLoaded);
    if (
      !isOrderLoaded &&
      !isLoadingOrder
      // && orderList.length === 0
    ) {
      onRefreshOrders();
    }
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     refreshOrders();
  //   }, 300000); // 60,000 milliseconds = 1 minute

  //   return () => {
  //     // Clean up the interval when the component unmounts
  //     clearInterval(intervalId);
  //   };
  // }, []);

  return (
    <IonPage ref={pageRef}>
      <IonHeader translucent={true}>
        <IonToolbar>
          {!showSearchbar && (
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          )}
          {!ios && !showSearchbar && <IonTitle>Your Orders</IonTitle>}
          {showSearchbar && (
            <IonSearchbar
              showCancelButton="always"
              placeholder="Search"
              onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
            ></IonSearchbar>
          )}

          <IonButtons slot="end">
            {!ios && !showSearchbar && (
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        {/* {isLoadingOrder && (
          <div className="spin">
            <IonSpinner name="bubbles" color="primary" />
          </div>
        )}
        {!isLoadingOrder && ( */}
        <>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">Your Orders</IonTitle>
            </IonToolbar>
            <IonToolbar>
              <IonSearchbar
                placeholder="Search"
                onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              ></IonSearchbar>
            </IonToolbar>
          </IonHeader>

          <div className="button-container">
            <IonButton onClick={onRefreshOrders} expand="full" color="primary">
              <IonIcon icon={syncSharp}></IonIcon>
            </IonButton>
          </div>

          <SyncStatus lastUpdate={orderDataLastUpdate} message={orderMessage} />

          {/* {isLoadingOrder && ( */}
          <div
            className={`${isLoadingOrder ? "refreshing" : ""} spinner-overlay`}
          >
            <IonSpinner name="bubbles" color="primary" />
            {/* <span className="span-loading">{t("global.loading_download")}</span> */}
            <span className="span-loading">Downloading new data</span>
          </div>
          {/* )} */}

          <IonList
            className={`${
              isLoadingOrder ? "refreshing" : "resetting"
            } list-container`}
          >
            {orderList.length === 0 && (
              <IonListHeader>No Orders Found</IonListHeader>
            )}

            {orderList.length !== 0 &&
              orderList.map((list: Order, index: number) => (
                <OrderItem
                  order={list}
                  listType={list.Status.toLowerCase()}
                  key={`order-${index}`}
                />
              ))}
          </IonList>
        </>
        {/* )} */}
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isLoading: state.data.dataLoading,
    isLoadingOrder: state.data.dataLoadingOrder,
    isOrderLoaded: state.data._orderLoaded,
    orderMessage: state.data.orderMessage,
    orderDataLastUpdate: state.data.lastUpdateOrders,
    orderList: selectors.getSearchedOrders(state),
    mode: getConfig()!.get("mode"),
  }),
  mapDispatchToProps: {
    setSearchText,
    refreshOrders,
  },
  component: React.memo(OrderPage),
});
