import { DataActions } from "./data.actions";
import { DataState } from "./data.state";

export function dataReducer(state: DataState, action: DataActions): DataState {
  switch (action.type) {
    case "set-loading":
      return { ...state, dataLoading: action.isLoading };
    case "set-ongoing-delivery-loading":
      return {
        ...state,
        dataLoadingOngoingDeliverys: action.isLoadingOngoingDeliverys,
      };
    case "set-past-delivery-loading":
      return {
        ...state,
        dataLoadingPastDeliverys: action.isLoadingPastDeliverys,
      };
    case "set-feedback-options-loading":
      return {
        ...state,
        dataLoadingFeedbackOptions: action.isLoadingFeedbackOptions,
      };
    case "set-driver-loading":
      return { ...state, dataLoadingDrivers: action.isLoadingDrivers };
    case "set-vehicle-loading":
      return { ...state, dataLoadingVehicles: action.isLoadingVehicles };
    case "set-loss-form-offline-data-loading":
      return {
        ...state,
        dataLoadingLossFormOfflineData: action.isLoadingLossFormOfflineData,
      };
    case "set-checklist-loading":
      return { ...state, dataLoadingChecklist: action.isLoadingChecklist };
    case "set-tank-loading":
      return { ...state, dataLoadingTank: action.isLoadingTank };
    case "set-justify-loading":
      return { ...state, dataLoadingJustify: action.isLoadingJustify };
    case "set-order-loading":
      return { ...state, dataLoadingOrder: action.isLoadingOrder };
    case "set-feedback-loading":
      return { ...state, dataLoadingFeedback: action.isLoadingFeedback };
    case "set-transport-loss-all-loading":
      return {
        ...state,
        dataLoadingTransportLossAll: action.isLoadingTransportLossAll,
      };
    case "set-justify-loaded":
      return {
        ...state,
        _isJustifyLoaded: action._isJustifyLoaded,
      };
    case "set-tank-loaded":
      return {
        ...state,
        _isTankLoaded: action._isTankLoaded,
      };
    case "set-checklist-loaded":
      return {
        ...state,
        _isChecklistLoaded: action._isChecklistLoaded,
      };
    case "set-ongoing-delivery-loaded":
      return {
        ...state,
        _ongoingDeliverysLoaded: action._isOngoingDeliverysLoaded,
      };
    case "set-past-delivery-loaded":
      return { ...state, _pastDeliverysLoaded: action._isPastDeliverysLoaded };
    case "set-order-loaded":
      return { ...state, _orderLoaded: action._isOrderLoaded };
    case "set-feedback-loaded":
      return { ...state, _feedbackLoaded: action._isFeedbackLoaded };
    case "set-transport-loss-all-loaded":
      return {
        ...state,
        _transportLossAllLoaded: action._isTransportLossAllLoaded,
      };
    case "set-ongoing-deliverys-message":
      return {
        ...state,
        ongoingDeliverysMessage: action.ongoingDeliverysMessage,
      };
    case "set-ongoing-deliverys-status":
      return {
        ...state,
        ongoingDeliverysStatus: action.ongoingDeliverysStatus,
      };
    case "set-past-deliverys-message":
      return {
        ...state,
        pastDeliverysMessage: action.pastDeliverysMessage,
      };
    case "set-past-deliverys-status":
      return {
        ...state,
        pastDeliverysStatus: action.pastDeliverysStatus,
      };
    case "set-order-message":
      return {
        ...state,
        orderMessage: action.orderMessage,
      };
    case "set-order-status":
      return {
        ...state,
        orderStatus: action.orderStatus,
      };
    case "set-feedback-message":
      return {
        ...state,
        feedbackMessage: action.feedbackMessage,
      };
    case "set-feedback-status":
      return {
        ...state,
        feedbackStatus: action.feedbackStatus,
      };
    case "set-transport-loss-all-message":
      return {
        ...state,
        transportLossAllMessage: action.transportLossAllMessage,
      };
    case "set-transport-loss-all-status":
      return {
        ...state,
        transportLossAllStatus: action.transportLossAllStatus,
      };
    case "set-server-message":
      return { ...state, message: action.msg };
    case "set-server-res-status":
      return { ...state, responseStatus: action.status };
    case "set-delivery-data":
    case "set-ongoing-delivery-data":
    case "set-past-delivery-data":
    case "set-order-data":
    case "set-feedback-data":
    case "set-transloss-data":
    case "set-lossform-offline":
    case "set-checklist-data":
    case "set-tank-data":
    case "set-justify-data":
    case "set-driver-detail":
    case "set-vehicle-detail":
    case "set-feedback-option":
      return { ...state, ...action.data };
    case "set-search-text":
      return { ...state, searchText: action.searchText };
    case "set-will-send-count":
      return { ...state, willSendCount: action.count };
  }
}
