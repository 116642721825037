import React, { useEffect, useRef, useState } from "react";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  useIonRouter,
} from "@ionic/react";
import { Route, Redirect, useHistory, useLocation } from "react-router";
import {
  archive,
  archiveOutline,
  basket,
  chatbox,
  pin,
  water,
} from "ionicons/icons";
import { App } from "@capacitor/app";

import DeliveryPage from "./Delivery";
import DeliveryDetail from "./DeliveryDetail";
import OrderPage from "./Order";
import FeedbackPage from "./Feedback";
import TransportLossAllPage from "./TransportLossAll";
import OrderDetail from "./OrderDetail";
import TransportLossDetail from "./TransportLossDetail";
import FeedbackDetail from "./FeedbackDetail";
import ScanPage from "./ScanPage";
import StockPage from "./StockPage";
import SalePage from "./SalePage";
import GainlossPage from "./GainlossPage";
import Help from "../components/Help";
import Profile from "../components/Profile";

interface MainTabsProps {}

const MainTabs: React.FC<MainTabsProps> = () => {
  let iontabbarhide = false;
  const router2 = useIonRouter();
  const history2 = useHistory();
  //const location2 = useLocation();

  const routeInfo2 = router2.routeInfo;
  const pathname2 = routeInfo2.pathname;
  //console.log(router2);
  //console.log(history2);
  //console.log(location2);

  // To start listening for location changes...
  /*
  let unlisten = history2.listen((setState) => {
    // The current location changed.
  });
  */

  if (
    pathname2.includes("/tabs/delivery/") ||
    pathname2.includes("/tabs/order/") ||
    pathname2.includes("/tabs/loss/") ||
    pathname2.includes("/tabs/message/")
  ) {
    iontabbarhide = true;
  } else {
    iontabbarhide = false;
  }

  if (
    routeInfo2.routeAction == "push" &&
    (pathname2.includes("/tabs/delivery") ||
      pathname2.includes("/tabs/order") ||
      pathname2.includes("/tabs/loss") ||
      pathname2.includes("/tabs/message"))
  ) {
    // console.log("back route ....");
    // Later, when you are done listening for changes...
    //unlisten();
    //history2.replace('/tabs/delivery');
    //history2.replace('/tabs/delivery');
    //router2.push('/tabs/delivery');
  }

  const setRootTab = (event: any) => {
    // console.log(event);
    /*if (event?.tab == 'delivery'){
      history2.replace('/tabs/delivery',true);
    }
    if (event?.tab == 'order'){
      history2.replace('/tabs/delivery',true);
    }
    if (event?.tab == 'loss'){
      history2.replace('/tabs/delivery',true);
    }
    if (event?.tab == 'message'){
      history2.replace('/tabs/delivery',true);
    }*/
  };

  /*
  useEffect(() => {
    return history2.listen((location): void => {
        console.log(location.pathname);
        if (location.pathname === '/tabs/order') {
            // do stuff
            console.log('back to /tabs/order'); 
            history2.replace('/tabs/delivery');
        }
        location.pathname = '/tabs/delivery';
    });
  }, []);
  */

  /*
  document.addEventListener("backbutton", function (e) {
    e.preventDefault();
    if (window.location.pathname === "/tabs/delivery"  || window.location.pathname === "/tabs/order"  || window.location.pathname === "/tabs/message" || window.location.pathname === "/tabs/loss") {
      let ans = window.confirm("Are you sure");
      if (ans) {
        App.exitApp();
      }
    }
  }, true);
  */

  let timer: any;
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  const handleLogoutTimer = () => {
    {
      timer = setTimeout(() => {
        // console.log("DIDALAM TIMER");
        resetTimer();
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, resetTimer);
        });
        logoutAction();
      }, 3600000);
    }
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    // console.log("COBA LOGOUT");
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        if (window.location.pathname !== "/login") {
          resetTimer();
          handleLogoutTimer();
        }
      });
    });
  }, []);

  const logoutAction = () => {
    history2.push("/logout");
  };

  return (
    <IonTabs onIonTabsDidChange={(e) => setRootTab(e.detail)}>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/delivery" />
        <Route
          path="/tabs/delivery"
          render={() => <DeliveryPage />}
          exact={true}
        />
        <Route path="/tabs/order" render={() => <OrderPage />} exact={true} />
        <Route
          path="/tabs/message"
          render={() => <FeedbackPage />}
          exact={true}
        />
        <Route
          path="/tabs/loss"
          render={() => <TransportLossAllPage />}
          exact={true}
        />
        <Route
          path="/tabs/delivery/:id"
          component={DeliveryDetail}
          exact={true}
        />
        <Route path="/tabs/order/:id" component={OrderDetail} exact={true} />
        <Route
          path="/tabs/loss/:id"
          component={TransportLossDetail}
          exact={true}
        />
        <Route
          path="/tabs/message/:id"
          component={FeedbackDetail}
          exact={true}
        />

        <Route
          path="/tabs/scan_rqcode"
          render={() => <ScanPage />}
          exact={true}
        />
        <Route path="/tabs/stock" render={() => <StockPage />} exact={true} />
        <Route path="/tabs/sales" render={() => <SalePage />} exact={true} />
        <Route
          path="/tabs/gain_loss"
          render={() => <GainlossPage />}
          exact={true}
        />
        <Route path="/tabs/help_feedback" component={Help} exact={true} />
        <Route path="/tabs/profile" component={Profile} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton
          tab="delivery"
          href="/tabs/delivery"
          disabled={iontabbarhide}
        >
          <IonIcon icon={archive} />
          <IonLabel>Delivery</IonLabel>
        </IonTabButton>
        <IonTabButton tab="order" href="/tabs/order" disabled={iontabbarhide}>
          <IonIcon icon={basket} />
          <IonLabel>Orders</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="message"
          href="/tabs/message"
          disabled={iontabbarhide}
        >
          <IonIcon icon={chatbox} />
          <IonLabel>Messages</IonLabel>
        </IonTabButton>
        <IonTabButton tab="loss" href="/tabs/loss" disabled={iontabbarhide}>
          <IonIcon icon={water} />
          <IonLabel>Loss</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
